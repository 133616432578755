<template>
  <div id="Borrado">
      <v-container style="background:#FFFFFF" class="pb-0" fluid>
          <v-row>
            <v-col  sm="3" cols="12">
            <v-container :class="!$vuetify.breakpoint.mobile ? 'mt-10 ml-16':''" :style="!$vuetify.breakpoint.mobile ? '':'padding-left:60px'">
              <div :class="!$vuetify.breakpoint.mobile ? 'ml-4':''" :style="!$vuetify.breakpoint.mobile ? '':'margin-right:15%'">
                <v-img eager
                class="mx-auto"
                width="160"
                height="auto"
                :src="require('../../../assets/imgs/IconBorrado.svg')"
                />
              </div>
              <div :class="!$vuetify.breakpoint.mobile ? '':'mx-auto mr-11'" :style="!$vuetify.breakpoint.mobile ? '':'max-width:250px'">
                <p class="headline text-center font-weight-bold mb-0" style="color:#707070">BORRADO DE TELÉFONO</p>
                <p class="text-center font-weight-bold mt-0" style="color:#707070">(Sólo Android)</p>
                <p style="color:#838383">Podrás borrar la información de tu teléfono remotamente (volviendo a un estado de fábrica). Esto es recomendado sólo si no crees posible recuperar tu teléfono y/o si necesitas proteger información privada o delicada.</p>
              </div>
          </v-container>
          </v-col>

          <v-col class="px-0" sm="9" cols="12">
              <v-img eager
                :style="!$vuetify.breakpoint.mobile ? 'margin-left:15%':''"
                :width="!$vuetify.breakpoint.mobile ? '100%':'80%'"
                :class="!$vuetify.breakpoint.mobile ? '':'mx-auto'"
                height="auto"
                :src="require('../../../assets/imgs/BorradoTelefono.png')"
                />
          </v-col>
        </v-row>
      </v-container>
  </div>
</template>

<script>
export default {
  name: "Borrado",
  data() {
    return {};
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
</style>