<template>
  <v-container fill-height fluid style="background-color: #D0282E" ref="containerInfo">
    <v-container fill-height>
      <v-row class="text-center" align-center justify-center>
        <v-col cols="12" class="align-center justify-center">
          <v-img
            :src="require('../../assets/blanco.png')"
            class="my-3 mx-auto"
            height="160"
            width="160"
          />
          <p class="text-white title">Estamos mejorando nuestro sitio web</p>
          <p class="text-white">Pronto podrás conocer todas las novedades de SOSCLICK</p>
          <v-dialog
            v-model="dialog"
            :max-width="dialogWidth"
            :scrollable="false"
            persistent
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="white"
                dark
                outlined
                depressed
                v-bind="attrs"
                v-on="on"
              >
                Ver video SOSCLICK
              </v-btn>
            </template>

            <v-card class="pa-0" :height="this.dialogHeight + 'px'" style="overflow-y: hidden;">
              <v-card-text class="pa-0">
                <youtube
                  :player-width="dialogWidth"
                  :player-height="dialogHeight"
                  video-id="d80oRIllaYU"
                  :player-vars="{start: 0, autoplay: 1, modestbranding: 1, rel: 0}"
                  @ready="playerReady"></youtube>
              </v-card-text>
              <v-btn
                icon
                color="#D0282E"
                @click="closeModal()"
                style="position: absolute; top: 20px; right: 20px;"
              >
                <v-icon x-large>mdi-close-circle</v-icon>
              </v-btn>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
  export default {
    name: 'InConstruction',
    data: () => ({
      dialog: false,
      dialogWidth: 200,
      dialogHeight: 200
    }),
    mounted(){
      this.dialogHeight = this.$refs.containerInfo.clientHeight * 0.85
      this.dialogWidth = this.$refs.containerInfo.clientWidth * 0.7
    },
    methods: {
      playerReady(event){
        this.player = event.target
      },
      closeModal(){
        this.dialog = false
        this.player.stopVideo()
      }
    }
  }
</script>
