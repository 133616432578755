<template>
  <v-container class="px-0 pb-0" style="background:#FFFFFF" fluid>
    <v-container>
      <v-row align="center">
          <v-col cols="12" sm="6">
            <v-img eager
              :class="!$vuetify.breakpoint.mobile ? 'mb-5':'mx-auto mb-5'"
              :width="!$vuetify.breakpoint.mobile ? '300':'150'"
              :src="require('../../../assets/imgs/IconSeguridad.svg')"
              />
            <div>
              <p class="display-1 font-weight-bold mb-2 ml-3" style="color:#D32527">RED DE SEGURIDAD</p>
              <p class="headline my-0 ml-3" style="color:#D32527">Crea tu Red de seguridad</p>
              <p class="mt-5" style="color:#707070">Agrega a tus familiares, amigos y/o vecinos a tu red de seguridad SOSCLICK.</p>
              <p class="mt-5" style="color:#707070">Puedes hacerlo enviando el link de registro con tu código desde la aplicación, todos los usuarios que se registren con ese código quedarán asociados a tu red de seguridad. O también puedes hacerlo enviando una solicitud a un usuario registrado en SOSCLICK, sólo debes conocer su correo eléctronico, el sistema le pedirá, a ese usuario, confirmación antes de ingresar a tu red de seguridad.</p>
            </div>
          </v-col>

          <v-col cols="12" sm="6">
            <v-img eager
              width="100%"
              :src="require('../../../assets/imgs/ImgSeguridad.svg')"
              />
          </v-col>
      </v-row>
    </v-container>
    <v-container fluid :class="!$vuetify.breakpoint.mobile ? 'probando px-0':''" :style="!$vuetify.breakpoint.mobile ? '':'background:#f7f7f7'">
      <v-container :style="!$vuetify.breakpoint.mobile ? 'min-height: 1000px;':''">
        <v-row class="align-end fill-height" :style="!$vuetify.breakpoint.mobile ? 'min-height: 500px;':''">
          <v-col cols="12" sm="5" style="position: relative;">
            <v-img eager
              :style="!$vuetify.breakpoint.mobile ? 'position: absolute;bottom: 0;right: -100px;':''"
              :class="!$vuetify.breakpoint.mobile ? '':'d-none d-sm-flex'"
              :src="require('../../../assets/imgs/ImgGeneraAlerta.svg')"
              />
          </v-col>          
          <v-col class="text-start fill-height" cols="12" sm="7">
            <p :class="!$vuetify.breakpoint.mobile ? 'display-1 font-weight-bold mb-2':'display-1'"
               :style="!$vuetify.breakpoint.mobile ? 'color:#D32527; max-width: 400px;margin-left: 100px;':'color:#D32527;'"
             >AL GENERAR UNA ALERTA</p>
            <p class="mt-5" style="color:#707070; max-width: 400px;">Esta avisara a todos las personas que pertenezca a tu red ademas de los servicios de seguridad y nuestro equipo de SOSclick.</p>
            <p class="mt-5" style="color:#707070;">Esta notificación llega con la ubicación desde donde se generó la alerta cualquier usuario, servicio de seguridad o nuestros equipo de SOSclick pueden establecer una videollamada con el usuario que generó la Alerta o Contarlo por una llamada telefónica obteniendo de manera inmediata una respuesta en esos instantes tan difícil.</p>
              <v-img eager
              :class="!$vuetify.breakpoint.mobile ? 'd-none':''"
              :src="require('../../../assets/imgs/ImgGeneraAlerta.svg')"
              />
          </v-col>
        </v-row>
      </v-container>
    </v-container>
  </v-container>
</template>

<script>
export default {
  name: "RedSeguridad",
  data() {
    return {};
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
.probando{
  /*background-image: url('../../assets/imgs/ImgAlerta.svg');*/
  background: url('../../../assets/imgs/back-alerta.png');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
</style>