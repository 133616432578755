<template>
	<v-dialog
		v-model="$store.state.modalContact"
		:max-width="$vuetify.breakpoint.mobile ? '95vw':dialogWidth"
		scrollable
		persistent
	>
		<v-card class="pa-0 contact-form-container" :class="$vuetify.breakpoint.mobile ? 'mobile':''" :height="dialogHeight + 'px'" style="overflow-y: hidden;">
			<v-card-text class="pa-0 fill-height">
				<v-container fill-height style="z-index: 99;position: relative;">
					<v-row align="center" justify="center">
						<v-col cols="12" sm="6">
						</v-col>
						<v-col cols="12" sm="6">					
							<v-form
								ref="form"
								v-model="valid"
								lazy-validation
								class="d-flex flex-column flex-grow-1 px-5"
							>
								<h2 class="title font-weight-bold text-primary">COMUNÍCATE CON NOSOTROS</h2>	
								<p class="mb-0">Envíanos un mensaje con tus dudas, comentarios o solicitudes. Te responderemos a la brevedad.</p>
								<v-text-field
									v-model="nombre"
									:rules="required"
									label="Nombre"
									required
									color="grey"
									class="custom-placeholer-color"
								></v-text-field>
								<v-text-field
									v-model="email"
									:rules="emailRules"
									label="Email"
									required
									color="grey"
									class="custom-placeholer-color"
								></v-text-field>
								<v-text-field
									v-model="phone"
									:rules="requiredPhone"
									label="Teléfono"
									required
									counter="11"
									maxlength="11"
									hint="Con código de área. Ej.: 56912345678"
									persistent-hint
									color="grey"
									class="custom-placeholer-color"
								></v-text-field>
								<v-textarea
									v-model="message"
									:rules="required"
									name="input-7-1"
									color="grey"
									label="Mensaje"
								></v-textarea>
								<v-btn
									:loading="sending"
									:disabled="!valid"
									color="#D0282E"
									dark
									class="custom-button-disabled-sos"
									@click="sendMessage"
								>
									<p class="mb-0 white--text">Enviar</p>
								</v-btn>
								<v-alert
									v-show="alert.status"
									dense
									text
									:type="alert.type"
								>
									<p class="mb-0 body-2">{{alert.message}}</p>
								</v-alert>
							</v-form>
						</v-col>
					</v-row>					
				</v-container>
			</v-card-text>
			<v-btn
				icon
				color="#D0282E"
				@click="closeModal()"
				style="position: absolute; top: 20px; right: 20px;z-index: 99;"
			>
				<v-icon x-large>mdi-close-circle</v-icon>
			</v-btn>
		</v-card>
	</v-dialog>
</template>

<script>
	import {sendContactMessage} from '../../Helpers/api/general'
	export default {
		name: 'ContactoModal',
		data: () => ({
				sending: false,
				dialogWidth: 200,
				dialogHeight: 200,
				valid: true,
				email: '',
				nombre: '',
				phone: '',
				message: '',
				alert: {
					status: false,
					type: 'success',
					message: ''
				},
				emailRules: [
					v => !!v || 'Email es obligatorio',
					v => /.+@.+\..+/.test(v) || 'Email debe ser válido',
				],
				required: [
					v => !!v || 'Este campo es obligatorio'
				],
				requiredPhone: [
					v => !!v || 'Este campo es obligatorio',
					v => ( !!v && /^\d+$/.test(v) ) || 'Ingrese sólo números'
				],
		}),
		mounted() {
			this.dialogHeight = window.innerHeight * 0.85
			this.dialogWidth = window.innerWidth * 0.7
		},
		methods: {
			closeModal(){
				this.$store.commit('setModalContact', false)
				this.email = ''
				this.nombre = ''
				this.phone = ''
				this.message = ''
			},
			sendMessage(){
				const valid = this.$refs.form.validate()
				if(valid){
					this.sending = true
					const data = {
						email: this.email,
						nombre: this.nombre,
						phone: this.phone,
						message: this.message
					}
					sendContactMessage(data)
						.then(() => {
							this.sending = false
							this.alert = {
								status: true,
								type: 'success',
								message: 'Gracias por comunicarte con nosotros. Te responderemos lo más rápido posible.'
							}
						})
						.catch(() => {
							this.sending = false
							this.alert = {
								status: true,
								type: 'error',
								message: 'Hubo un error al intentar enviar el mensaje, por favor intenta nuevamente.'
							}
						})
					setTimeout(() => {
						this.alert = {
							status: false,
							type: 'success',
							message: ''
						}
						this.closeModal()
					}, 5000);
				}
			}
		},
	}
</script>
