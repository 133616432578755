<template>
  <div class="prefooter-container" id="PlanLampa">
      <v-container
         style="background:transparent">
          <v-row>
              <v-col cols="12">
                  <div class="text-center">
                      <p style="color:#D32527" class="display-2">PLAN SOSCLICK Lampa</p>
                  </div>
              </v-col>
          </v-row>

          <v-row justify="center">
              <v-col cols="12" sm="6">
                  <div class="rounded-lg" style="background:white">
                      <div>
                        <v-img eager
                        class="mx-auto"
                        max-width="700"
                        height="auto"
                        :src="require('../../../assets/imgs/PlanLmapa.png')"
                        />                          
                      </div>
                      <div class="mt-5">
                          <hr class="mx-auto" style="width:80%;border:solid 1px #FF0000">
                          <p style="color:#D32527" class="text-center my-3 display-1">PLAN MUNICIPAL LAMPA</p>
                          <hr class="mx-auto" style="width:80%;border:solid 0.1px #FF0000">
                      </div>
                      <div class="mx-auto mt-5 font-weight-light body-1 pb-5" style="max-width:500px; color:#838383">
                          
                        <p class="text-center mb-4">Este plan te dará acceso a que nuestros operadores puedan asistirte en caso de una emergencia, podrán comunicarse contigo agotando todos los medios posibles (Video llamada, llamada telefónica, SMS, Notificaciones).</p>
                        <p><v-icon class="mr-5" color="#4AE01D">mdi-check</v-icon>Asistencia SOSclick Monitoreo</p>
                        <p><v-icon class="mr-5" color="#4AE01D">mdi-check</v-icon>Asistencia SOSclick Alertas</p>
                        <p><v-icon class="mr-5" color="#4AE01D">mdi-check</v-icon>Asistencia SOSclick Red</p>
                        <p><v-icon class="mr-5" color="#4AE01D">mdi-check</v-icon>Teléfono Secundario Monitor</p>
                        <p><v-icon class="mr-5" color="#4AE01D">mdi-check</v-icon>Pérdida/Robo (Sólo Android)</p>
                      </div>

                      <div class="text-center font-weight-light caption pb-4" style="color:#838383">
                          <p class="mb-1">Disponible hasta el 28-02-2022</p>
                          <p>Máxima cantidad de usuarios en el plan 4000</p>
                      </div>
                  </div>
              </v-col>
          </v-row>
      </v-container>
  </div>
</template>

<script>
export default {
  name: "PlanLampa",
  data() {
    return {};
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
</style>