<template>
  <v-container
  fluid
  class="prefooter-container"
  :class="!$vuetify.breakpoint.mobile ? '':'mobile'"
  >
    <v-container style="min-height: 100vh">      
      <v-row>
        <v-col class="text-center" cols="12" sm="12">
          <h2 class="headline font-weight-regular text-primary text-center">PLANES</h2>
          <h2 class="display-2 font-weight-bold text-primary text-center">SOSCLICK</h2>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col :key="index" v-for="(plan,index) in $store.state.planes.planes" cols="12" sm="6">
          <div class="white fill-height">
            <Plan :plan="plan" :includes="$store.state.planes.includes" class="my-5" :bigger="true"/>
          </div>
        </v-col>
      </v-row>

    </v-container>
  </v-container>
</template>

<script>
  import Plan from './Plan'
  export default {
    name: 'Planes',
    components: {Plan},
    data: () => ({
      planes: [],
      includes: []
    }),
    mounted(){
    },
    methods: {
      toPrice(price){
        var formatter = new Intl.NumberFormat('es-ES', {
          style: 'currency',
          currency: 'CLP',
          currencyDisplay: 'symbol'
        });
        return formatter.format(price)
      },
      getTextInclude(include){
        const includes = this.includes.find((item) => {
          return item.id === include
        })
        return includes.name
      }
    }
  }
</script>

<style>
.p1{
  color: #838383;
  display: flex;
  align-items: flex-start;
}
.p2{
  color:#D32527
}
</style>