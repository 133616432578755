<template>
	<div>
		<Header v-if="$router.currentRoute.name != 'Badge'"/>
		<router-view></router-view>
		<Footer v-if="$router.currentRoute.name != 'Badge'"/>
		<Modals />
	</div>
</template>

<script>
/* eslint-disable */

	import {getPlanes} from '../../Helpers/api/general'
	import Header from './Header'
	import Footer from './Footer'
	import Modals from '../Modals/Index'
	export default {
		name: 'App',
		components: { Header, Footer, Modals },
		data: () => ({
		}),
		mounted(){
			this.getPlanesinfo()
		},
		methods: {
			async getPlanesinfo(){
				const planes = await getPlanes()
				const planesData = {
					planes: planes.planes,
					includes: planes.includes
				}
				console.log("planesData", planesData)
				this.$store.commit('setPlanes', planesData)
			}
		}
	};
</script>
