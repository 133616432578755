<template>
  <div id="Perdida">
      <v-container class="pb-0" fluid style="background:#D32527">
        <v-row class="align-center">
            <v-col  class="pb-0 pt-10" cols="12" sm="8">
                <v-img eager
                  class="mt-7"
                  style="margin-left:15%"
                  width="70%"
                  height="auto"
                  :src="require('../../../assets/imgs/PerdidaDispositivoCorrecto.svg')"
                  />
            </v-col>

            <v-col class="text-start" cols="12" sm="4">
              <v-container>
                <div class="text-center">
                  <v-img eager
                  class="mx-auto"
                  width="160"
                  height="auto"
                  :src="require('../../../assets/imgs/icon-perdida.svg')"
                  />
                </div>
                <div class="mt-5 mx-auto" style="max-width: 300px;">
                  <p class="headline text-center font-weight-bold mb-0" style="color:#FFFFFF;">PÉRDIDA DE TELÉFONO</p>
                <p class="text-center font-weight-bold mt-0" style="color:#FFFFFF">(Sólo Android)</p>
                  <p class="text-center" style="color:#FFFFFF;">Si se te pierde o te roban tu dispositivo podrás enviar una solicitud para que el teléfono te envíe (cada 1 hr) una foto de la cámara frontal, una de la cámara trasera y la posición del dispositivo.</p>
                  <v-btn
                    large
                    rounded
                    small
                    block
                    color="white"
                    @click="$store.commit('setModalVideoState', {videoID: 'CqPmrL-uuLA',open: true})"
                  >
                    <p class="mb-0 text-primary" :class="!$vuetify.breakpoint.mobile ? 'mr-3':'mr-0 caption'">Ver Video</p>
                    <v-icon class="text-primary">mdi-play</v-icon>
                  </v-btn>
                </div>
            </v-container>
          </v-col>
        </v-row>
    </v-container>

  </div>
</template>

<script>
export default {
  name: "Perdida",
  data() {
    return {};
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
</style>