import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './Router'
import VueYouTubeEmbed from 'vue-youtube-embed'
import VueSmoothScroll from 'vue2-smooth-scroll'
import './assets/scss/index.scss'
import store from './Stores/stores'

Vue.use(VueSmoothScroll)
Vue.use(VueYouTubeEmbed)

Vue.config.productionTip = false

new Vue({
  vuetify,
	router,
	store,
  render: h => h(App)
}).$mount('#app')
