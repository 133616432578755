<template>
  <v-container
    fill-height
    fluid
    style="background-color: white;min-height: 80vh;">
    <v-container fill-height>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="7" class="d-flex align-center justify-center" style="position: relative;">
            <v-img eager
              :src="require('../../assets/imgs/button-background.svg')"
              contain
              :max-width="!$vuetify.breakpoint.mobile ? '700':'100%'"
              class="align-center"
              :class="!$vuetify.breakpoint.mobile ? 'px-15':''"
            >
              <v-img eager
                :src="require('../../assets/imgs/button.svg')"
                :max-width="!$vuetify.breakpoint.mobile ? '200':'100'"
                contain
                class="mx-auto mb-15"
              />
            </v-img>
        </v-col>
        <v-col cols="12" sm="5">
          <h2 class="display-1 font-weight-bold text-primary">BOTÓN ALARMA</h2>
          <h2 class="display-1 font-weight-bold text-primary">SOSCLICK</h2>
          <p class="mb-0" style="color: #939393;">Hay segundos que pueden salvarte la vida por lo que con el botón SOSCLICK puedes generar alarmas sin la necesidad de interactuar con tu teléfono.</p>
          <v-btn
            large
            outlined
            rounded
            :block="$vuetify.breakpoint.mobile"
            color="#D0282E"
            class="px-6 py-2 my-4"
            @click="$store.commit('setModalContact', true)"
          >
            <p class="mb-0">Solicitar Botón</p>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
  export default {
    name: 'Boton',
    data: () => ({
    }),
    methods: {
    },
  }
</script>