<template>
  <v-container
  fluid
  class="tracking-container"
  ref="containerInfo"
  :class="!$vuetify.breakpoint.mobile ? '':'mobile'"
  >
    <!-- <div class="background" v-if="!$vuetify.breakpoint.mobile"></div> -->
    <video-player
      class="tracking-video-background-top"
      ref="videoPlayer"
      :playsinline="true"
      :options="playerOptions">
    </video-player>
    <v-container fill-height>
      <v-row style="z-index: 99;">
        <v-col cols="12" sm="5">
          <v-row align="center">
            <v-col cols="7" sm="12">
              <v-img eager
              width="30%"
              :src="require('../../assets/imgs/IconTracking.svg')"
              />
              <h2 class="display-1 font-weight-bold white--text mb-5">TRACKING SOSCLICK</h2>
            </v-col>
            <v-col cols="5" sm="12" v-if="$vuetify.breakpoint.mobile">
              <v-img eager
                width="100%"
                height="auto"
                class="animate-float"
                :src="require('../../assets/imgs/phone-map.svg')"
              ></v-img>
            </v-col>
          </v-row>
          <p class="body-1 white--text">Podrás activar y visualizar en tiempo real la ubicación de tu dispositivo. La ruta queda en nuestra base de datos, por lo que puedes visualizarla cuando quieras.</p>

          <div class="d-flex align-center my-10" style="position: relative;">
            <v-icon color="white" style="position: absolute;left: -20px;">mdi-crosshairs-gps</v-icon>
            <v-divider color="white" style="border-width: 1px;"></v-divider>
          </div>

          <h2 class="display-1 font-weight-bold white--text mb-5">ACTIVACIÓN REMOTA TRACKING SOSCLICK</h2>
          <p class="body-1 white--text">Podrás activar de forma remota el tracking de tu dispositivo.</p>
          <v-btn
            large
            outlined
            rounded
            :block="$vuetify.breakpoint.mobile"
            color="white"
            class="px-6 py-2 my-5"
            @click="$store.commit('setModalVideoState', {videoID: 'ybev920xBwI',open: true})"
          >
            <p class="mb-0 mr-3">Ver Video</p>
            <v-icon>mdi-play</v-icon>
          </v-btn>
        </v-col>
        <v-col class="justify-end" cols="12" sm="7">
          <v-img eager
            v-if="!$vuetify.breakpoint.mobile"
            width="70%"
            height="auto"
            class="animate-float"
            style="position: absolute; right: -10%; top: 15%; margin: auto;"
            :src="require('../../assets/imgs/phone-map.svg')"
          ></v-img>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
  import 'video.js/dist/video-js.css'
  import { videoPlayer } from 'vue-video-player'

  export default {
    name: 'Tracking',
    components: {videoPlayer},
    data: () => ({
      playerOptions: null
    }),
    mounted(){
      const playerWidth = Math.round( this.$refs.containerInfo.clientWidth )
      const playerHeight = Math.round( this.$refs.containerInfo.clientHeight )
      this.playerOptions = {
        // videojs options
        autoplay: true,
        muted: true,
        loop: true,
        fluid: true,
        aspectRatio: playerWidth + ':' + playerHeight,
        controls: false,
        language: 'es',
        sources: [{
          type: "video/mp4",
          src: "/backgroundtracking.mp4"
        }]
      }
      this.loadPlayer = true
    },
    methods: {
    },
  }
</script>