<template>
  <v-container style="background: #f7f7f7">
    <v-row class="flex-md-row-reverse">
      <v-col cols="12" md="6">
        <v-img
          class="mx-auto mt-16"
          max-width="250"
          :src="require('./../../assets/soscamexta.png')"
        ></v-img>
      </v-col>
      <v-col class="pl-16" cols="12" md="6">
        <div class="mt-16">
          <h2 class="display-1 font-weight-bold text-primary">SOSCAM EXT-A</h2>
          <p class="mb-0" style="color: #939393;">
            Descubre la revolución en seguridad con SOSCAM EXT-A, la Cámara de Seguridad IP que se integra perfectamente con el innovador Sistema de Seguridad SOSClick. Monitorea y protege tu Almacén, Local Comercial, Hogar o Pyme desde cualquier lugar a través de la potente App SOSClick. Aprovecha la oportunidad de garantizar la seguridad de tus seres queridos y posesiones más preciadas.
          </p>
        </div>

        <div class="mt-5">
          <v-btn
            large
            outlined
            rounded
            :block="$vuetify.breakpoint.mobile"
            color="#D0282E"
            class="px-6 py-2 my-4"
            style="z-index: 123;"
            @click="$router.push('/soscam-ext-a')"
          >
            <p class="mb-0">Solicitar Cámara</p>
          </v-btn>
<!--           <v-btn
            large
            text
            :block="$vuetify.breakpoint.mobile"
            color="#D0282E"
            class="mx-2 px-6 py-2 my-4"
            href="/catalogo_soscam.pdf"
            target="_blank"
          >
            <p class="mb-0">Descargar Catálogo</p>
          </v-btn> -->
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "SosCam",
  data() {
    return {};
  },
};
</script>

<style>
</style>