<template>
  <div
    style="height: 100%; max-width: 1000px; margin: 0 auto;"
  >
    <div
      v-if="loading"
      style="min-height: 180px; height: 100%"
      class="d-flex flex-column align-items-center justify-content-center"
    >
	<div style="margin: 0 auto;">
		<v-progress-circular indeterminate class="d-flex justify-center"></v-progress-circular>
	</div>
<!--       <p class="mb-0">{{ messageLoader }}</p> -->
    </div>
    <div
      v-if="reloads >= 3"
      style=" height: 100%"
      class="d-flex flex-column align-items-center justify-content-center"
    >
      <p class="mb-0">
        <small>Esta cámara presenta problemas de conexión</small>
      </p>
      <p class="mb-0"><small>Puedes reconectar presionando el botón</small></p>
      <v-btn
        size="sm"
        class="mt-2 mx-0 d-flex align-items-center"
        color="secondary"
        variant="outline"
        @click="reload(true)"
      >
        <font-awesome-icon
          icon="sync"
          style="color: #424242; font-size: 10px"
        />
        <p class="mb-0 ml-2" style="color: #424242">Reconectar</p>
      </v-btn>
    </div>
    <div
      v-show="!loading && reloads < 3"
      style="min-height: 180px; height: 100%"
    >
      <div v-if="showVideo">
        <video
          autoplay
          :muted="!cameraData.main"
          v-show="!loading && remoteStream"
          :srcObject.prop="remoteStream"
          data-setup='{"fluid": true, "liveui": true}'
          class="video-js camera-video"
          :style="
            cameraData.main ? { borderRadius: '20px' } : { borderRadius: '0' }
          "
        ></video>
        <div class="mt-1 d-flex justify-center" style="margin: 0 auto; gap: 20px; width: 200px;" v-if="!cameraData.main">
          <v-btn
			style="z-index: 3;"
            size="sm"
            class="mx-0 flex-fill"
            color="#DF375E"
            variant="outline"
            @click="ptzMove('left')"
          >
			<v-icon color="#FFFF">mdi-arrow-left</v-icon>
          </v-btn>
          <v-btn
			style="z-index: 3;"
            size="sm"
            class="mx-0 flex-fill"
            color="#DF375E"
            variant="outline"
            @click="ptzMove('up')"
          >
            <v-icon color="#FFFF">mdi-arrow-up</v-icon>
          </v-btn>
          <v-btn
			style="z-index: 3;"
            size="sm"
            class="mx-0 flex-fill"
            color="#DF375E"
            variant="outline"
            @click="ptzMove('down')"
          >
            <v-icon color="#FFFF">mdi-arrow-down</v-icon>
          </v-btn>
          <v-btn
			style="z-index: 3;"
            size="sm"
            class="mx-0 flex-fill"
            color="#DF375E"
            variant="outline"
            @click="ptzMove('right')"
          >
            <v-icon color="#FFFF">mdi-arrow-right</v-icon>
          </v-btn>
<!--           <v-btn
            size="sm"
            class="mx-0 flex-fill"
            color="secondary"
            variant="outline"
            @click="reload()"
          >
            <font-awesome-icon
              icon="sync"
              style="color: #424242; font-size: 10px"
            />
          </v-btn> -->
        </div>

        <div
          v-if="cameraData.main"
          style="
            display: flex;
            justify-content: space-between;
            margin-top: 10px;
          "
        >
          <div class="ml-2">
            <v-btn
              size="sm"
              class="mx-0"
              variant="outline"
              @click="ptzMove('left')"
            >
              <font-awesome-icon
                icon="arrow-left"
                style="color: #000; font-size: 20px"
              />
            </v-btn>
            <v-btn
              size="sm"
              class="mx-0"
              variant="outline"
              @click="ptzMove('up')"
            >
              <font-awesome-icon
                icon="arrow-up"
                style="color: #000; font-size: 20px"
              />
            </v-btn>
            <v-btn
              size="sm"
              class="mx-0"
              variant="outline"
              @click="ptzMove('down')"
            >
              <font-awesome-icon
                icon="arrow-down"
                style="color: #000; font-size: 20px"
              />
            </v-btn>
            <v-btn
              size="sm"
              class="mx-0"
              variant="outline"
              @click="ptzMove('right')"
            >
              <font-awesome-icon
                icon="arrow-right"
                style="color: #000; font-size: 20px"
              />
            </v-btn>
          </div>

          <!-- 					<div>
						<v-btn size="sm" variant="outline">
							<font-awesome-icon icon="lightbulb" style="color: #000;font-size: 20px" />
						</v-btn>
						<v-btn variant="outline" style="flex: 1;font-size: 12px;">
							<font-awesome-icon class="mr-2" icon="exclamation-triangle" style="font-size:16px;color: #000;"/>
						</v-btn>
					</div> -->
          <!-- 					<div class="d-flex">
					<div v-show="availableLight(item) || availableAlarm(item)"  v-for="(item, index) in commands" :key="index">
						<v-btn v-if="availableLight(item)" size="sm" variant="outline" @click="toggleLight()">
							<font-awesome-icon icon="lightbulb" :style="{ color: item && item.value ? '#f5f242' : '#000' }" style="font-size: 20px;" />
						</v-btn>
						<v-btn v-if="availableAlarm(item)" variant="outline" style="flex: 1;font-size: 12px;" @click="toggleAlarm()">
								<font-awesome-icon icon="exclamation-triangle" :style="{ color: item && item.value ? '#eb4034' : '#000' }" style="font-size:16px;;"/>
							</v-btn>
					</div>
					</div> -->
          <div>
            <v-btn
              v-if="availableCommand('floodlight_switch')"
              size="sm"
              variant="outline"
              @click="sendBoolCommands('floodlight_switch')"
            >
              <font-awesome-icon
                icon="lightbulb"
                :style="{
                  color: commands.find(
                    (obj) => obj.code === 'floodlight_switch'
                  ).value
                    ? '#f5f242'
                    : '#000',
                }"
                style="font-size: 20px"
              />
            </v-btn>
            <!-- 						<v-btn v-if="availableCommand('siren_switch')" size="sm" variant="outline" @click="sendBoolCommands('siren_switch')">
							<font-awesome-icon icon="exclamation-triangle" :style="{ color: commands.find(obj => obj.code === 'siren_switch').value ? '#eb4034' : '#000' }" style="font-size: 20px" />
						</v-btn> -->
            <v-btn
              v-if="availableCommand('basic_flip')"
              variant="outline"
              style="flex: 1; font-size: 12px"
              @click="sendBoolCommands('basic_flip')"
            >
              <font-awesome-icon
                icon="sync"
                style="color: #000; font-size: 16px"
              />
            </v-btn>
          </div>

          <div class="mr-2">
            <v-btn
              v-if="!audio"
              size="sm"
              variant="outline"
              @click="enableAudio()"
            >
              <font-awesome-icon
                icon="microphone"
                style="color: #000; font-size: 20px"
              />
            </v-btn>
            <v-btn
              v-if="audio"
              size="sm"
              variant="outline"
              @click="disableAudio()"
            >
              <font-awesome-icon
                icon="microphone-slash"
                style="color: #000; font-size: 20px"
              />
            </v-btn>
            <!-- 					<v-btn size="sm" variant="outline" @click="reload()">
						<font-awesome-icon icon="sync" style="color: #000;font-size: 20px" />
					</v-btn> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "video.js/dist/video-js.css";
import { cameraPtzMove, getStatusCamera, commandTuyaDevice, getTuyaCamera } from "../../Helpers/api/cameras";

export default {
  name: "CameraView",
  props: [
    "cameraData",
    "showCameraViewComponent",
    "ptzWithKeys",
    "socket",
    "main",
  ],
  data() {
    return {
      showVideo: true,
      loading: true,
      messageLoader: "Conectado con Cámara",
      cameraStream: null,
      videojsOptions: {},
      player: null,
      reloaderCount: 0,
      reloads: 0,
      socketUrl: "https://devapis.anubys.cl:3000",
      localStream: null,
      remoteStream: null,
      audio: false,
      pc: null,
      alarm: false,
      commands: [],
      reconnecionCameraData: false,
    };
  },
  computed: {
    camera() {
		return this.reconnecionCameraData ? this.reconnecionCameraData : this.cameraData;
    },
  },
  mounted() {
    /* 		if (this.main) {
			this.audio = true
		} */
    if (this.cameraData) {
      this.startSocket(this.cameraData);
/*       this.getStatusCamera(); */
    }
  },
  methods: {
    startSocket(cameras) {
      this.socket.emit("joinRoomWithConfig", [cameras]);
      this.startConnection();
    },
    getTuyaCamera() {
      const data = { camera_id: this.cameraData.deviceId };
      getTuyaCamera(data).then((res) => {
        this.reconnecionCameraData = res.connections;
        this.startSocket(res.connections);
      });
    },
    availableCommand(command) {
      return this.commands.some((obj) => obj.code === command);
    },
    sendBoolCommands(command) {
      const value = this.commands.find((obj) => obj.code === command).value
        ? 0
        : 1;
      const data = { camera_id: this.cameraData.deviceId, command, value };

      if (command == "basic_flip") {
        data.value = 0;
      }

      commandTuyaDevice(data).then(() => {
        this.getStatusCamera();
      });
    },
    getStatusCamera() {
      const data = { camera_id: this.cameraData.deviceId };
      getStatusCamera(data)
        .then((res) => {
          this.commands = res.commands;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    setMainVideo() {
      this.handlerSetMainCamera(this.cameraData);
      this.$store.commit("setMainCamera", null);
      this.$store.commit("setMainCamera", this.cameraData);
    },
    /* 	  setMainVideo() {
			this.$store.commit('setMainCamera', null)
			setTimeout(() => {
    			this.$store.commit('setMainCamera', this.cameraData)
			}, 500);
  		}, */
    setRef() {
      this.videoRef = this.cameraData.camera_id + +new Date();
      return this.videoRef;
    },
    destroyPlayer(stop = false) {
      this.showVideo = false;
      this.loading = true;
      this.cameraStream = null;
      this.videojsOptions = {};
      if (this.player) {
        this.player.dispose();
      }
      this.player = null;
      this.removeListeners();
      if (stop) {
        this.messageLoader = "Conectado con Cámara";
        this.reloaderCount = 0;
        this.reloads = 0;
      }
    },
    ptzKeyHandler(e) {
      const ptzValues = {
        37: "left",
        38: "up",
        39: "right",
        40: "down",
      };
      this.ptzMove(ptzValues[e.keyCode]);
    },
    removeListeners() {
      window.removeEventListener("keyup", this.ptzKeyHandler);
    },
    setKeyListeners() {
      window.addEventListener("keyup", this.ptzKeyHandler);
    },
    disableAudio() {
      this.audio = false;
      if (this.localStream) {
        const tracks = this.localStream.getTracks();
        const audioTrackIndex = tracks.findIndex(
          (track) => track.kind === "audio"
        );
        if (audioTrackIndex !== -1) {
          tracks[audioTrackIndex].enabled = false;
        }
      }
    },
    enableAudio() {
      this.audio = true;
      if (this.localStream) {
        const tracks = this.localStream.getTracks();

        const audioTrackIndex = tracks.findIndex(
          (track) => track.kind === "audio"
        );
        if (audioTrackIndex !== -1) {
          tracks[audioTrackIndex].enabled = true;
        }
      }
    },
    setListeners() {
      this.loading = false;
      this.showVideo = true;
      /* 	
			if (this.player) {
				this.player.on('error', () => {
					console.log("Errorcin")
					this.reload()
				})

				this.player.on('playing', () => {
					this.loading = false
				})
				
				this.player.tech().on('retryplaylist', () => {
					this.reloaderCount++
					if(this.reloaderCount >= 5){
						this.reloads++
						if(this.reloads < 3){
							this.reload()
						}
						else{
							if (this.player) {
								this.player.dispose()
							}
							this.player = null
						}
					} */
      // console.log('retryplaylist')
      /* 			}) */
      /* 	} */
    },
    reload(restartCounter = false) {
      if (restartCounter) {
        this.reloads = 0;
      }
      this.reloaderCount = 0;
      this.destroyPlayer();
      /* 			setTimeout(() => {
				this.requestVideoUrl()
			}, 500); */
    },
    ptzMove(ptzDirection) {
      const ptzValues = {
        left: "6",
        up: "0",
        down: "4",
        right: "2",
      };
      cameraPtzMove({
        camera_id: this.cameraData.deviceId,
        ptz: ptzValues[ptzDirection],
      });
    },

    startConnection() { 
      let pc = new RTCPeerConnection({ iceServers: this.camera.ices });
		console.log(pc);

      navigator.mediaDevices
        .getUserMedia({ video: true, audio: false })
        .then((stream) => {
			console.log(stream);
			
          this.localStream = stream;
          pc.addStream(this.localStream);
          this.disableAudio();

          pc.createOffer({
            offerToReceiveAudio: false,
            offerToReceiveVideo: true,
          })
            .then((offer) => pc.setLocalDescription(offer))
            .then(() =>
              this.socket.emit("offer", {
                device_id: this.camera?.deviceId,
                offer: pc.localDescription,
              })
            );

          pc.ontrack = (event) => {
            this.remoteStream = event.streams[0];
          };

          pc.onicecandidate = (event) => {
            if (event.candidate) {
              this.socket.emit("candidate", {
                device_id: this.camera?.deviceId,
                candidate: event.candidate,
              });
            }
          };

          pc.addEventListener("connectionstatechange", () => {
            console.log("Connection State:", pc.connectionState);
            if (pc.connectionState === "connected") {
              this.loading = false;
              this.disableAudio();
            }
            console.log(pc,pc.signalingState,"!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!");

            if (pc?.connectionState === "failed" && pc?.signalingState !== "closed") {
				console.log("___________");
				pc.close();
              this.messageLoader = "Conectando con Cámara";
              this.reloaderCount = 0;
              this.reloads = +1;
              //Reiniciar Conexión
              setTimeout(() => {
                this.getTuyaCamera();
              }, 3000);
            }
          });
        })
        .catch((error) => {
          console.error("Error accessing media devices.", error);
        });

      this.socket.on("answer", (answer) => {
        if (this.camera.deviceId == answer.deviceId) {
          pc.setRemoteDescription(new RTCSessionDescription(answer.answer));
        }
      });

      this.socket.on("candidate", (candidate) => {
        if (this.camera.deviceId == candidate.deviceId) {
          pc.addIceCandidate(new RTCIceCandidate(candidate.candidate));
        }
      });

      this.socket.on("disconnect", () => {
        if (pc) {
          pc.close();
          pc = null;
        }
      });
    },
  },
  watch: {
    cameraData: function () {
      this.disableAudio();
    },
  },
};
</script>

<style scoped>
.camera-video {
  width: 100%;
  height: 100%;
}
</style>
