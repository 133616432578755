var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticStyle:{"background":"white"},attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"text-center",attrs:{"sm":"12","cols":"12"}},[_c('div',{staticStyle:{"color":"#d0282e","max-width":"700px","margin":"auto"}},[_c('p',{staticClass:"display-2 font-weight-bold"},[_vm._v("PANEL DE USUARIO")]),_c('p',{staticClass:"title font-weight-light"},[_vm._v(" Al ser usuario de SOSclick tendrás acceso a nuestro "),_c('b',[_vm._v("Panel de Usuario")]),_vm._v(", en el cual podrás configurar y activar todas las funcionalidades de SOSclick ")])])])],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"sm":"12","cols":"12"}},[_c('v-img',{staticClass:"rounded-xl",style:(!_vm.$vuetify.breakpoint.mobile ? '' : 'margin-left:10px'),attrs:{"eager":"","width":"100%","height":"auto","src":require('../../assets/imgs/PanelCorrecto.png')}})],1)],1),_c('v-row',{staticClass:"align-end py-10",staticStyle:{"background":"#d32527"}},[_c('v-col',{attrs:{"sm":"3","cols":"12"}},[_c('v-container',{class:!_vm.$vuetify.breakpoint.mobile ? 'ml-16' : ''},[_c('div',{staticClass:"text-center"},[_c('v-img',{staticClass:"mx-auto",attrs:{"eager":"","width":"160","height":"auto","src":require('../../assets/imgs/icon-bloqueo.svg')}})],1),_c('div',{staticClass:"mt-5 mx-auto",staticStyle:{"max-width":"250px"}},[_c('p',{staticClass:"headline text-center font-weight-bold white--text"},[_vm._v(" Bloqueo (Sólo Android) ")]),_c('p',{staticClass:"white--text text-center"},[_vm._v(" Podrás bloquear el dispositivo remotamente, si no le habías asignado clave a tu dispositivo podrás generar una para que sea solicitada antes de permitir el desbloqueo. ")])])])],1),_c('v-col',{style:(!_vm.$vuetify.breakpoint.mobile ? 'position:relative;' : ''),attrs:{"sm":"9","cols":"12"}},[_c('v-img',{style:(!_vm.$vuetify.breakpoint.mobile
            ? 'max-width: 800px; position: absolute;margin: auto; bottom: 0; left: 0; right: 0;'
            : ''),attrs:{"eager":"","height":"auto","src":require('../../assets/imgs/Msnbloqueo.svg')}})],1)],1),_c('v-row',{staticClass:"align-center",staticStyle:{"background":"#f7f7f7"}},[_c('v-col',{staticClass:"pb-0 pt-10",attrs:{"cols":"12","sm":"8"}},[_c('v-img',{staticClass:"mt-7",staticStyle:{"margin-left":"15%"},attrs:{"eager":"","width":"70%","height":"auto","src":require('../../assets/imgs/PerdidaDispositivoCorrecto.svg')}})],1),_c('v-col',{staticClass:"text-start",attrs:{"cols":"12","sm":"4"}},[_c('v-container',[_c('div',{staticClass:"text-center"},[_c('v-img',{staticClass:"mx-auto",attrs:{"eager":"","width":"160","height":"auto","src":require('../../assets/imgs/icon-perdida.svg')}})],1),_c('div',{staticClass:"mt-5 mx-auto",staticStyle:{"max-width":"300px"}},[_c('p',{staticClass:"headline text-center font-weight-bold",staticStyle:{"color":"#d32527"}},[_vm._v(" Pérdida/Robo (Sólo Android) ")]),_c('p',{staticClass:"text-center font-weight-regular subtitle-1",staticStyle:{"color":"#707070"}},[_vm._v(" Si se te pierde o te roban tu dispositivo podrás enviar una solicitud para que el teléfono te envíe (cada 1 hr) una foto de la cámara frontal, una de la cámara trasera y la posición del dispositivo. ")]),_c('v-btn',{attrs:{"large":"","rounded":"","small":"","block":"","color":"white"},on:{"click":function($event){return _vm.$store.commit('setModalVideoState', {
                videoID: 'CqPmrL-uuLA',
                open: true,
              })}}},[_c('p',{staticClass:"mb-0 text-primary",class:!_vm.$vuetify.breakpoint.mobile ? 'mr-3' : 'mr-0 caption'},[_vm._v(" Ver Video ")]),_c('v-icon',{staticClass:"text-primary"},[_vm._v("mdi-play")])],1)],1)])],1)],1),_c('v-row',{staticStyle:{"height":"85vh"}},[_c('v-col',{attrs:{"sm":"3","cols":"12"}},[_c('v-container',{class:!_vm.$vuetify.breakpoint.mobile ? 'mt-16 ml-16' : '',style:(!_vm.$vuetify.breakpoint.mobile ? '' : 'padding-left:60px')},[_c('div',{class:!_vm.$vuetify.breakpoint.mobile ? 'ml-4' : '',style:(!_vm.$vuetify.breakpoint.mobile ? '' : 'margin-right:20%')},[_c('v-img',{staticClass:"mx-auto",attrs:{"eager":"","width":"200","height":"auto","src":require('../../assets/imgs/IconBorrado.svg')}})],1),_c('div',{class:!_vm.$vuetify.breakpoint.mobile ? 'mx-auto' : '',style:(!_vm.$vuetify.breakpoint.mobile
              ? 'max-width:280px'
              : 'max-width:300px')},[_c('p',{staticClass:"headline text-center font-weight-bold",staticStyle:{"color":"#707070"}},[_vm._v(" BORRADO DE TELÉFONO ")]),_c('p',{staticStyle:{"color":"#838383"}},[_vm._v(" Podrás borrar la información de tu teléfono remotamente (volviendo a un estado de fábrica). Esto es recomendado sólo si no crees posible recuperar tu teléfono y/o si necesitas proteger información privada o delicada. ")])])])],1),_c('v-col',{staticClass:"px-0",attrs:{"sm":"9","cols":"12"}},[_c('v-img',{class:!_vm.$vuetify.breakpoint.mobile ? '' : '',style:(!_vm.$vuetify.breakpoint.mobile ? 'margin-left:15%' : ''),attrs:{"eager":"","height":"auto","src":require('../../assets/imgs/BorradoTelefono.png')}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }