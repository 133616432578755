<template>
  <div>
    <v-container v-if="$vuetify.breakpoint.mobile">
      <v-row align="center" justify="center" class="py-5 asistencia-container">
        <v-col cols="8" sm="12">
          <h2 class="display-1 font-weight-bold text-primary">ASISTENCIA SOSCLICK</h2>
        </v-col>
        <v-col cols="4" sm="12">
        </v-col>
      </v-row>
    </v-container>
    <v-container
      fill-height
      fluid
      :class="!$vuetify.breakpoint.mobile ? 'asistencia-container':''">
      <v-container>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="7">
            <h2 class="display-1 font-weight-bold text-primary" v-if="!$vuetify.breakpoint.mobile">ASISTENCIA SOSCLICK</h2>
            <v-row align="center" justify="center">
              <v-col cols="12" sm="4">
                <v-img eager
                  :src="require('../../assets/imgs/247.svg')"
                  max-height="150"
                  contain
                />
              </v-col>
              <v-col cols="12" sm="8">
                <h3 class="headline text-primary">Monitoreo <span class="caption">(En implementación)</span></h3>
                <p class="mb-0" style="color: #939393;">Nuestros operadores podrán monitorear los dispositivos que habilites como monitor y activar las redes de seguridad de SOSCLICK en caso de que una emergencia sea detectada (Por Videollamada, llamada teléfonica, SMS o notificaciones).</p>
              </v-col>
            </v-row>
            <v-row align="center" justify="center">
              <v-col cols="12" sm="4">
                <v-img eager
                  :src="require('../../assets/imgs/red-badge.svg')"
                  max-height="150"
                  contain
                />
              </v-col>
              <v-col cols="12" sm="8">
                <h3 class="headline text-primary">Red</h3>
                <p class="mb-0" style="color: #939393;">Al generar una alerta, tu red de seguridad podrá comunicarse contigo por videollamada.</p>
              </v-col>
            </v-row>
            <v-row align="center" justify="center">
              <v-col cols="12" sm="4">
                <v-img eager
                  :src="require('../../assets/imgs/alert.svg')"
                  max-height="150"
                  contain
                />
              </v-col>
              <v-col cols="12" sm="8">
                <h3 class="headline text-primary">Alertas</h3>
                <p class="mb-0" style="color: #939393;">Cuando generes una alerta podrás ser ayudado por tu red de seguridad y luego por nuestros operadores.</p>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="5">
          </v-col>
        </v-row>
      </v-container>
    </v-container>
  </div>
</template>

<script>
  export default {
    name: 'Asistencia',
    data: () => ({
    }),
    methods: {
    },
  }
</script>