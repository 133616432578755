<template>
    <main style="background-color: rgb(208, 40, 46); min-height: 100vh; display:flex; flex-direction: column; padding-top: 100px;">
        <img style="width:100px; margin: 0 auto;" :src="require('@/assets/logo_sosclick.png')"/>
        <div v-if="device == 'desktop'" class="button-wrapper">
          <a target="_blank" href="https://play.google.com/store/apps/details?id=com.naturalphone.sosclick"><img class="googleplay" src="@/assets/googleplay.png" alt=""></a>
          <a target="_blank" href="https://apps.apple.com/us/app/sosclick/id1517076968"><img class="appstore" src="@/assets/AppStore.svg" alt=""></a>
        </div>
        <div v-else style="display: flex; flex-direction: column; align-items: center; gap: 12px;">
            <p class="mb-0" style="font-size: 14px; color: #FFF;">Redirigiendo</p>
            <div class="loader"></div> 
        </div>

    </main>
</template>

<script>
export default {
    data () {
        return {
            device: ''
        }
    },
    mounted() {
        if (window.navigator.userAgent.includes('Android')) {
            window.location.replace('https://play.google.com/store/apps/details?id=com.naturalphone.sosclick')
        } else if (window.navigator.userAgent.includes('Mac')) {
            window.location.replace('https://apps.apple.com/us/app/sosclick/id1517076968')
        } else {
            this.device = 'desktop';
        }
    }, 
}
</script>

<style lang="scss" scoped>
.loader {
  width: 40px;
  aspect-ratio: 1;
  --c: linear-gradient(#FFF 0 0);
  --r1: radial-gradient(farthest-side at bottom,#FFF 93%,#FFF);
  --r2: radial-gradient(farthest-side at top   ,#FFF 93%,#FFF);
  background: 
    var(--c) ,var(--r1),var(--r2),
    var(--c) ,var(--r1),var(--r2),
    var(--c) ,var(--r1),var(--r2);
  background-repeat: no-repeat;
  animation: l2 1s infinite alternate;
}
@keyframes l2 {
  0%,25% {
    background-size: 8px 0,8px 4px,8px 4px,8px 0,8px 4px,8px 4px,8px 0,8px 4px,8px 4px;
    background-position: 0 50%,0 calc(50% - 2px),0 calc(50% + 2px),50% 50%,50% calc(50% - 2px),50% calc(50% + 2px),100% 50%,100% calc(50% - 2px),100% calc(50% + 2px);
 }
 50% {
    background-size: 8px 100%,8px 4px,8px 4px,8px 0,8px 4px,8px 4px,8px 0,8px 4px,8px 4px;
    background-position: 0 50%,0 calc(0% - 2px),0 calc(100% + 2px),50% 50%,50% calc(50% - 2px),50% calc(50% + 2px),100% 50%,100% calc(50% - 2px),100% calc(50% + 2px);
 }
 75% {
    background-size: 8px 100%,8px 4px,8px 4px,8px 100%,8px 4px,8px 4px,8px 0,8px 4px,8px 4px;
    background-position: 0 50%,0 calc(0% - 2px),0 calc(100% + 2px),50% 50%,50% calc(0% - 2px),50% calc(100% + 2px),100% 50%,100% calc(50% - 2px),100% calc(50% + 2px);
 }
 95%,100% {
    background-size: 8px 100%,8px 4px, 8px 4px,8px 100%,8px 4px,8px 4px,8px 100%,8px 4px,8px 4px;
    background-position: 0 50%,0 calc(0% - 2px),0 calc(100% + 2px),50% 50%,50% calc(0% - 2px),50% calc(100% + 2px),100% 50%,100% calc(0% - 2px),100% calc(100% + 2px);
 }
}

.googleplay {
  width: 200px;
  height: 80px;
}
.appstore {
    width: 170px;
}
.button-wrapper {
  display: flex;justify-content: center;
  align-items: center;
  gap: 12px;
}
</style>