<template>
    <header style="min-height: 70vh; padding-top: 30px; background: transparent linear-gradient(183deg, #E6B54C 0%, #FFFDC1 51%, #B58910 87%, #FFFF83 100%) 0% 0% no-repeat padding-box;" class="v1 relative-box " id="home-area">
      <div class="image-side-box right-box hidden-xs hidden-sm  box-bg" style="display:flex;flex-direction: column; align-items:center; justify-content:center;">
        <h4 v-if="$store.state.roulettePrize && $store.state.roulettePrize.uid == 'camera'" class="RouletteContainer__Text__Oferta" style="color: #000; font-size: 24px;  margin-bottom: 0; font-weight: 800;">Premio válido por lo próximos :</h4>
        <span v-if="$store.state.roulettePrize && $store.state.roulettePrize.uid == 'camera'" id="time"  class="Count mt-2">00:00</span>
        <img
          src="../../assets/Camara.png"
          class="layer bg-camara"
          style="width:60%; max-width: 420px;"
          data-depth="0.35"
          alt="SOSCAM"
          v-if="$store.state.roulettePrize"
        />
      </div>
      <div class="container">
        <div class="row">
          <div class="col-xs-12 col-md-5">
            <div class="text-box" style="border-top: solid 2px white;border-bottom: solid 2px white;">
              <h2 style="    line-height: 1.4;font-weight: 800; font-size: 50px; color:#796843;"  v-if="$store.state.roulettePrize && $store.state.roulettePrize.uid == 'percent'">
                GANASTE <br />
                UN {{$store.state.roulettePrize.amount}}% EN TU <br />
                SOSCAM
              </h2>
              <h2 v-else style="    line-height: 1.4;font-weight: 800; font-size: 50px; color:#796843;">
                GANASTE
                <br />
                UNA SOSCAM
              </h2>
              <div class="hr"></div>
              <p v-if="$store.state.roulettePrize && $store.state.roulettePrize.uid == 'camera'">Resgístrate y reclama tu premio</p>
              <div class="space-10"></div>
              </div>
              <p style="font-size: 24px; color: #796843;" v-if="success">Felicidades! Recibirás un correo con los datos de tu premio</p>
              <div v-else>
              <v-expansion-panels style="margin-top: 20px; margin-bottom: 20px;" v-if="!logged && $store.state?.roulettePrize && $store.state?.roulettePrize?.uid == 'camera'">
                    <v-expansion-panel>
                      <v-expansion-panel-header>
                        Ya tengo una cuenta
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        
                        <v-form ref="form" v-model="valid" lazy-validation >
                          <v-row>
                          
                          <v-col cols="12" md="4">
                            <v-text-field v-model="cart.email" :rules="emailRules" label="Correo Electrónico" required></v-text-field>
                          </v-col>
                          <v-col cols="12" md="4">
                            <v-text-field type="password" label="Contraseña" v-model="password"></v-text-field>
                          </v-col>
                          <v-col cols="12" md="4" class="mt-3">

                                <v-btn :loading="loadingbtn" style="z-index: 1; margin-right:8px;background-color: #E23336; color: #FFFF;"   @click="logIn">Ingresar</v-btn>
                                <span v-if="messageLogin"  style="display: block; margin-bottom: 8px;">* {{messageLogin}}</span>
                          </v-col>
                          </v-row>
                        </v-form> 
                      </v-expansion-panel-content>
                        </v-expansion-panel>
                  </v-expansion-panels>

          <v-form ref="form" v-model="valid" lazy-validation v-if="$store.state?.roulettePrize && $store.state?.roulettePrize?.uid == 'camera'">
                <v-row>
                    <v-col cols="12" sm="6">
                      <v-text-field required :rules="required" v-model="cart.name" label="Nombre"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" v-if="logged">
                      <v-text-field required :rules="required" v-model="cart.last_name" label="Apellido"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-text-field v-model="cart.email" :rules="emailRules" label="Correo Electrónico" required></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" v-if="!logged">
                        <v-text-field v-model="rut" :rules="rutRules" @change="(rut) => {formatRut(rut)}" required label="Rut"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field maxlength="11" :counter="11" :rules="requiredPhone" required v-model="cart.phone" label="Teléfono"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" v-if="!logged">
                      <v-select required label="Género" :items="genderOptions" item-text="text" item-value="value"  v-model="sexo"></v-select>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field required :rules="required" v-model="cart.address" label="Dirección"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" v-if="logged">
                      <v-text-field  v-model="cart.zip_code" label="Código ZIP"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-select disabled :rules="required" label="País" :items="paises" item-text="name" item-value="id" v-model="pais"></v-select>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-select :rules="required" label="Región" :items="regiones" item-text="region" item-value="id" v-model="region"></v-select>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-select :rules="required" label="Comuna" :disabled="!region" :items="comunas" item-text="comuna" item-value="id" v-model="cart.comuna_id"></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" style="z-index:1;" v-if="!logged">
                      <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    style="z-index: 1 !important;"
                    v-model="birthday"
                    :rules="required"
                    label="Fecha Nacimiento"
                    readonly
                    v-bind="attrs"
                    prepend-inner-icon="mdi-calendar"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  ref="picker"
                  locale="es-ES"
                  v-model="birthday"
                  :max="new Date().toISOString().substr(0, 10)"
                  min="1950-01-01"
                  @change="(date) => $refs.menu.save(date)"
                ></v-date-picker>
              </v-menu>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field v-if="!logged" :rules="required" required type="password" label="Contraseña" v-model="password"></v-text-field>
                    </v-col>
                </v-row>
                <span v-if="message" style="display: block; margin-bottom: 8px;">* {{message}}</span>

                <v-btn outlined :loading="loadingbtn" v-if="!logged" style="z-index: 10; margin-right:8px; margin: 0 auto; display:flex;"  color="dark" @click="signUpByCode">Registrar cuenta</v-btn>
                <v-btn outlined :loading="loadingbtn" v-if="logged" style="z-index: 10; margin-right:8px; margin: 0 auto; display:flex;"  color="dark" @click="createPurchaseOrderCouponCam">Reclamar Premio</v-btn>
              </v-form>
        </div>

              <div class="hero-buttons-container">
                <button
                v-if="$store.state.roulettePrize && $store.state.roulettePrize.uid == 'percent'"
                  style="z-index:98; margin-top: 12px;"
                  @click="addToCart"
                  class="win-btn">
                  Comprar
                </button>
            </div>
          </div>
        </div>
      </div>
    </header>
</template>

<script>
import { validate, format } from 'rut.js'
import {  getPaisesActivos, getRegionesActivas, getComunasActivas, signUpByCode,  login, getUser, createPurchaseOrderCouponCam } from '../../Helpers/api/general'
import moment from "moment";

export default {

    data () {
        return {
      product_data: {product_id: 8, qty: 1},
    logged: false,
    registerForm: false,
    valid: true,
    genderOptions: [{text: 'Masculino', value:1}, {text: 'Femenino', value:2}],
    requiredPhone: [
        v => !!v || 'Este campo es obligatorio',
            v => ( !!v && /^\d+$/.test(v) ) || 'Ingrese sólo números'
        ],
        rutRules: [
            v => !!v || 'Este campo es obligatorio',
            v => (!!v || 'Este campo es obligatorio') && (validate(v) || 'Rut no válido')
          ],
        emailRules: [
            v => !!v || 'Email es obligatorio',
			v => /.+@.+\..+/.test(v) || 'Email debe ser válido',
		],
            required: [v => !!v || 'Este campo es obligatorio',],
            loadingbtn: false,
    step: 1,
    menu: false,
    price: 123,
    paises: [],
    pais: 81,
    regiones: [],
    region: null,
    comunas: [],
    cart: {
        name: '',
        last_name: '',
        email: '',
        phone: '',
        address: '',
        zip_code: '',
        comuna_id: '',
        product_id: 8
      },
      qty: 1,
      password: '',
      rut: '',
      birthday: new Date().toISOString().substr(0, 10),
      sexo: '',
      message: '',
      messageLogin: '',
      code: '',
      applied_code: '',
      discount: null,
      product: {},
      error_code: null,
      loaderCheckout: true,
      //search city
      isLoading: false,
      city: null,
      search: null,
      cities: [],
      listado_tarifas: [],
      tarifa_envio: null,
      costo_envio: null,
      loadingEnvio: false,
      no_products: false,
      success: false
        }
    },
    methods: {
      addToCart() {
        const productIndex = this.$store.state.products.findIndex(product => product.product_id === this.product_data.product_id);
        if (productIndex  === -1) {
          this.$store.commit('setCart', [...this.$store.state.products, this.product_data])
        } else {
          const arr = this.$store.state.products
          arr[productIndex].qty += 1;
          this.$store.commit('setCart', arr)
      }
      setTimeout(() => {
        //loader
        this.$router.push({ name: 'SOSCamSingle' })
      }, .5);
      },
      formatRut(rut) {
        this.rut = format(rut)
      },
      signUpByCode() {
    const data = {
        name: this.cart.name,
        email: this.cart.email,
        direccion: this.cart.address,
        telefono: this.cart.phone,
        rut: this.rut,
        sexo: this.sexo,
        birthday: this.birthday,
        password: this.password,
        pais: this.pais,
        region: this.region,
        comuna: this.cart.comuna_id
        }
      if(this.$refs.form.validate()){
        this.loadingbtn = true
        signUpByCode(data).then(() => {
          this.loadingbtn = false;
            this.message = '';
            this.logIn()
        }).catch(er => {
          this.loadingbtn = false
          this.message = er[0].includes('Ese email ya está en uso, por favor verifica.') ? er[0] : null
        })
      }
    },
        logIn() {
      this.loadingbtn = true
      login({email: this.cart.email, password: this.password}).then(() => {
        this.loadingbtn = false
        this.logged = true
        this.registerForm = true
        getUser().then(res => {
          const { user } = res
          this.cart.name = user.name
          this.cart.email = user.email
          this.cart.address = user.info.direccion
          this.cart.phone = user.info.telefono
/*           this.pais = user.info.pais */
          this.region = user.info.region
          this.cart.comuna_id = user.info.comuna
        })
        this.messageLogin = ''
        location.reload();
      }).catch(err => {
        if (err.response.status == 401) {
          this.messageLogin = 'Contraseña Incorrecta'
        }
        this.loadingbtn = false
      })
    },
        logout() {
      localStorage.removeItem("userToken");
      this.logged = false;
      this.region = null
      this.cart = {
        name: '',
        last_name: '',
        email: '',
        phone: '',
        address: '',
        zip_code: '',
        comuna_id: null
      }
      this.qty = 1,
      this.password = ''
      this.rut = ''
      this.birthday = new Date().toISOString().substr(0, 10)
      this.sexo = ''
    },  
    createPurchaseOrderCouponCam() {
      console.log(1);
     if(this.$refs.form.validate()){
        this.loadingbtn = true
      const data = {...this.cart}
      createPurchaseOrderCouponCam(data).then(() => {
        this.loadingbtn = false
        this.success = true
      }).catch(e => {
        this.loadingbtn = false
        console.log(e);
      })
      }
    },
  startTimer(duration, display) {
    var timer = duration, minutes, seconds;
    const self = this
    const timeInterval = setInterval(function () {
        minutes = parseInt(timer / 60, 10)
        seconds = parseInt(timer % 60, 10);

        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;

        display.textContent = minutes + ":" + seconds;

        if (--timer <= 0) {
          clearInterval(timeInterval);
          self.$store.commit('setRoulettePrize', null)
          localStorage.removeItem('prize')
          timer = 0
            /* timer = duration; */
        }
    }, 1000);
},
    },
    mounted() {
          getPaisesActivos().then(res => {
        this.paises = res.paises
        getRegionesActivas({pais_id: 81}).then(res => {
            this.regiones = res.regiones
        })
    })

    getComunasActivas({region_id: this.region}).then(res => {
      this.comunas = res.comunas
    })

        const userToken = localStorage.getItem('userToken')
    if (userToken) {
      this.logged = true
      this.registerForm = true
      getUser().then(res => {
        const { user } = res
        this.cart.name = user.name
        this.cart.email = user.email
        this.cart.address = user.info.direccion
        this.cart.phone = user.info.telefono
        this.pais = user.info.pais ? user.info.pais : 81
        this.region = user.info.region
        this.cart.comuna_id = user.info.comuna
      })
    } else {
      this.logged = false
      this.registerForm = true
    }


  if (this.$store.state.roulettePrize.uid == 'camera') {
    const count_camera = localStorage.getItem('count_camera')
      if (!count_camera) {
        localStorage.setItem('count_camera', moment().add(30, 'minutes'))
      let expirationTime = moment().add(30, 'minutes').diff(moment(), 'seconds')
      let display = document.querySelector('#time');
        this.startTimer(expirationTime, display);
      } else {
        let expirationTime = moment(count_camera).diff(moment(), 'seconds') 
        let display = document.querySelector('#time');
        this.startTimer(expirationTime, display);
      }
    }

    },
      watch: {
    pais: function () {
        getRegionesActivas({pais_id: this.pais}).then(res => {
            this.regiones = res.regiones
        })
    },
    region: function () {
        getComunasActivas({region_id: this.region}).then(res => {
            this.comunas = res.comunas
            setTimeout(() => {
              if (this.region == 7) {
                this.search = 'santiago'
              }
            }, 500);
            setTimeout(() => {
              if (this.region == 7) {
                this.city = this.cities[0]
              }
          }, 3000);
        })
    },
    menu (val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
    },
      }
}
</script>

<style lang="scss" scoped>
/*     @import '../../assets/css/style.css';  */
@import "../../assets/css/normalize.css";
/*     @import '../../assets/css/bootstrap-min.css'; */
@import "../../assets/css/owl-carousel-min.css";
@import "../../assets/css/lity-min.css";
@import "../../assets/css/font-awesome-min.css";
@import "../../assets/css/animate.css";
@import "../../assets/css/theme.css";
@import "../../assets/css/helper.css";
@import "../../assets/css/responsive.css";


.title,
.rate {
  color: #d32527;
}
.bttn-1 {
  color: #fff;
  &:hover {
    color: #d32527;
  }
}
.bttn-3 {
  color: #d32527;
  &:hover {
    color: #fff;
  }
}

.bg-camara {
  background-image: url("../../assets/imgs/circle-bg-2.png");
  background-size: cover;
}

.about-section {
  background-image: url("../../assets/image.png");
}
.bttn-2:hover {
  color: #d32527 !important;
}
.top-title {
  font-size: 32px;
  color: rgb(46, 0, 0);
}
.title {
  font-size: 36px !important;
}
@media (max-width: 568px) {
  .intro-area {
    background-size: cover !important;
  }
  .top-title {
    color: #fff;
  }
}

///////////////////////////////////////////////////////////////////////////
//hero
.hero-buttons-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  gap: 20px;
}
@media (min-width: 768px) {
  .hero-buttons-container {
    flex-direction: row;
  }
}


.win-btn {
background: transparent linear-gradient(261deg, #E6B54C 0%, #FFFDC1 16%, #B58910 87%, #FFFF83 100%) 0% 0% no-repeat padding-box;
box-shadow: 0px 3px 6px #00000029;
border-radius: 29px;
  color: #766948;
  padding: 12px 50px;
  &:hover {
    background-color: #fff;
    color: #d32527;
  }
}

@media (min-width: 768px) {
  .win-btn {
    height: max-content;
  }
}

.Count {
            margin-right: 0;
            background-color: #000;
            color: #FAEAA8;
            padding: 8px 16px;
            border-radius: 14px;
            font-weight: 800;
        }
</style>