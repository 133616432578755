<template>	
	<div class="white fill-height d-flex flex-column" v-if="plan" style="min-height: 821px;">
		<v-img eager
			style="max-height: 240px;"
			:src="require('../../assets/imgs/planes/'+plan.id+'@2x.png')"
			:height="bigger ? '240px':'175px'"
		/>
		<div class="py-2 px-5 mt-4 d-flex flex-column justify-space-between" style="flex: 1;">
			<div>
				<div style="min-height: 120px;">
					<p class="d-flex justify-center align-center headline text-center mb-0 textcolor-sosclick text-uppercase font-weight-regular text-primary">
						{{plan ? plan.name:''}}
					</p>
					<p class="text-center mb-5" style="color: #838383;">{{plan.description}}</p>
				</div>
				<v-divider class="my-3"></v-divider>
				<p class="my-0">
					<v-icon color="#4AE01D" x-small>
						mdi-check
					</v-icon>
					<span class="ml-2" style="color: #838383;">Cantidad máxima de usuarios en red: {{plan.max_red}}</span>
				</p>

				<p class="my-0">
					<v-icon color="#4AE01D" x-small>
						mdi-check
					</v-icon>
					<span class="ml-2" style="color: #838383;">Cantidad máxima de dispositivos: {{plan.max_devices}}</span>
				</p>

				<p class="mt-2 mb-0 font-weight-medium" style="color: #838383;">Funcionalidades</p>
				<div class="mb-0 d-flex align-center" :key="index" v-for="(include,index) in plan.includes">
					<v-icon color="#4AE01D" x-small>
						mdi-check
					</v-icon>
					<div class="ml-2">
						<p class="mb-0" style="color: #838383;">{{includes ? getTextInclude(include):''}}</p>
					</div>
				</div>
				<p class="my-2 font-weight-bold">
					<v-icon color="#4AE01D" x-small>
						mdi-check
					</v-icon>
					<span class="ml-2" style="color: #838383;">Precio: {{toPrice(plan.price)}} mensual</span>
				</p>
			</div>
			<div class="text-center" v-if="plan.price > 0">
				<v-btn
					large
					outlined
					rounded
					block
					color="#4AE01D"
					class="px-6 py-2 my-4"
					:href="'https://usuarios.sosclick.cl?getplan=' + plan.id"
					target="_blank"
				>
					<v-icon>mdi-lock-check</v-icon>
					<p class="mb-0 ml-3">Contratar Plan</p>
				</v-btn>
				<p class="caption font-weight-light">Para contratar el servicio serás dirigido a tu portal de usuario SOSclick ya que debes ingresar a tu cuenta para asociar el plan correspondiente.</p>
			</div>
			<p class="caption font-weight-light text-center" v-else>Este plan es gratuito orientado a la red de seguridad de un usuario.</p>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'Plan',
		props: ['plan', 'includes', 'bigger'],
		data: () => ({
			loading: false
		}),
		mounted(){
		},
		methods: {
			toPrice(price){
				var formatter = new Intl.NumberFormat('es-ES', {
					style: 'currency',
					currency: 'CLP',
					currencyDisplay: 'symbol'
				});
				return formatter.format(price)
			},
			getTextInclude(include){
				const includesData = this.includes.find((item) => {
					return item.id === include
				})
				return includesData.name
			}
		}
	};
</script>