<template>
  <div id="Secundario">
      <v-container class="pb-0" fluid style="background:#F7F7F7">
          <v-row class="">
              <v-col class="pb-0" cols="12" sm="8">
                <div :class="!$vuetify.breakpoint.mobile ? 'mt-16':''">
                    <v-img eager
                      style="margin-left:20%"
                      :width="!$vuetify.breakpoint.mobile ? '40%':''"
                      height="auto"
                      :src="require('../../../assets/imgs/Secundario.svg')"
                      />
                </div>   
              </v-col>

              <v-col cols="12" sm="4">
                <div class="mt-16">  
                    <v-img eager
                    :class="!$vuetify.breakpoint.mobile ? 'ml-12':'mx-auto'"
                    width="200"
                    height="auto"
                    :src="require('../../../assets/imgs/SecundarioB.svg')"
                    />
                </div>

                <div :class="!$vuetify.breakpoint.mobile ? 'mr-16':'mx-auto'" style="max-width:300px;">
                    <p class="headline text-center font-weight-bold" style="color:#707070">Teléfono Secundario Monitor</p>
                    <p style="color:#838383;">Podrás configurar como monitor cualquier dispositivo secundario, como, por ejemplo, un teléfono que no uses. Al hacer esto habilitarás ese dispositivo como una cámara ip que podrás visualizar en cualquier momento desde la app SOSclick. Además, tienes la opción de permitir que la central de cámaras de la comuna pueda acceder a visualizar el video.</p>
                </div>
              </v-col>
          </v-row>
      </v-container>
  </div>
</template>

<script>
export default {
  name: "Secundario",
  data() {
    return {};
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
</style>