<template>
  <v-container class="px-0" style="background: #ffffff" fluid>
    <v-container>
      <v-row>
        <v-col>
          <v-col class="text-center" sm="12" cols="12">
            <div style="color: #d0282e; max-width: 1000px; margin: auto">
              <p class="display-2 font-weight-bold">RED DE SEGURIDAD</p>
              <p class="title font-weight-light">
              La red de seguridad permite que vecinos, familiares y amigos se
              organicen por un bien el bien común de su comuna esto es
              fundamental para evitar el aislamiento que facilita la comisión de
              delitos; Tener residentes que se conozcan y se comuniquen entre sí
              puede ayudar a reducir los robos y asaltos, así como a facilitar
              la relación con la Seguridad Pública y Privada
              </p>
            </div>
          </v-col>
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col cols="12" sm="6">
          <v-img
            eager
            class="mx-auto mb-5"
            width="200"
            :src="require('../../assets/imgs/IconSeguridad.svg')"
          />
          <div>
            <p
              class="display-1 font-weight-bold mb-2 ml-3"
              style="color: #d32527"
            >
              CREA TU RED DE SEGURIDAD
            </p>
            <p class="headline my-0 ml-3" style="color: #d32527">
              Crea tu Red de seguridad
            </p>
            <p class="mt-5 body-1 font-weight-regular" style="color: #707070">
              Agrega a tus familiares, amigos y/o vecinos a tu red de seguridad
              SOSCLICK.
            </p>
            <p class="mt-5 body-1 font-weight-regular" style="color: #707070">
              Puedes hacerlo enviando el link de registro con tu código desde la
              aplicación, todos los usuarios que se registren con ese código
              quedarán asociados a tu red de seguridad. O también puedes hacerlo
              enviando una solicitud a un usuario registrado en SOSCLICK, sólo
              debes conocer su correo eléctronico, el sistema le pedirá, a ese
              usuario, confirmación antes de ingresar a tu red de seguridad.
            </p>
          </div>
        </v-col>

        <v-col cols="12" sm="6">
          <v-img
            eager
            width="100%"
            :src="require('../../assets/imgs/ImgSeguridad.svg')"
          />
        </v-col>
      </v-row>
    </v-container>
    <v-container
      fluid
      :class="!$vuetify.breakpoint.mobile ? 'probando px-0' : ''"
      :style="!$vuetify.breakpoint.mobile ? '' : 'background:#f7f7f7'"
    >
      <v-container
        :style="!$vuetify.breakpoint.mobile ? 'min-height: 1000px;' : ''"
      >
        <v-row
          class="align-end fill-height"
          :style="!$vuetify.breakpoint.mobile ? 'min-height: 500px;' : ''"
        >
          <v-col cols="12" sm="5" style="position: relative">
            <v-img
              eager
              :style="
                !$vuetify.breakpoint.mobile
                  ? 'position: absolute;bottom: 0;right: -100px;'
                  : ''
              "
              :class="!$vuetify.breakpoint.mobile ? '' : 'd-none d-sm-flex'"
              :src="require('../../assets/imgs/ImgGeneraAlerta.svg')"
            />
          </v-col>
          <v-col class="text-start fill-height" cols="12" sm="7">
            <p
              :class="
                !$vuetify.breakpoint.mobile
                  ? 'display-1 font-weight-bold mb-2'
                  : 'display-1'
              "
              :style="
                !$vuetify.breakpoint.mobile
                  ? 'color:#D32527; max-width: 400px;margin-left: 100px;'
                  : 'color:#D32527;'
              "
            >
              AL GENERAR UNA ALERTA
            </p>
            <div class="ml-10">
              <p
                class="mt-5 ml-16 body-1 font-weight-regular"
                style="color: #707070; max-width: 400px"
              >
                SOSclick enviará notificaciones a todas las personas que
                pertenezcan a tu red de seguridad y a los operadores del panel
                SOSclick en tu cuadrante. Esta notificación se envía con la
                ubicación desde donde se generó la alerta. Cualquier usuario de
                tu red o algún operador del panel SOSclick pueden intentar
                establecer una videollamada con el usuario que generó la Alerta
                o Contactarlo por una llamada telefónica obteniendo de manera
                inmediata una comunicación con este usuario. .
              </p>
            </div>

            <v-img
              eager
              :class="!$vuetify.breakpoint.mobile ? 'd-none' : ''"
              :src="require('../../assets/imgs/ImgGeneraAlerta.svg')"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-container>
    <v-container>
      <v-row class="mb-16 mt-10">
        <v-col cols="12" class="text-center">
          <v-container>
            <div style="max-width: 700px; margin: auto">
              <p class="display-1 font-weight-bold" style="color: #d32527">
                VIDEOLLAMADAS ENTRE TU RED DE SEGURIDAD
              </p>
              <v-img
                eager
                class="my-10"
                :src="require('../../assets/imgs/ImgVideoLlamadas.svg')"
              />
              <p style="color: #707070" class="display-1 font-weight-regular">SOSCLICK te da la posibilidad de comunicarte por videollamada con los usuarios de tu red de seguridad</p>
              <p style="color: #707070" class="subtitle-1 font-weight-regular">
                Las videollamadas generadas por alertas son aceptadas
                inmediatamente, sin la necesidad de que el usuario que presenta
                la emergencia deba aceptarla. Por el contrario, las llamadas
                generadas entre la red de seguridad, sin la previa activación de
                una alerta, deben ser aceptadas por el usuario que la recibe.
              </p>
            </div>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
export default {};
</script>

<style>
.probando {
  /*background-image: url('../../assets/imgs/ImgAlerta.svg');*/
  background: url("../../assets/imgs/back-alerta.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
</style>