<template>
  <div>
    <SocialNetworks />
    <Home ref="home"/>
    <Bajada ref="servicios" />
    <Asistencia />
    <Bloqueo/>
    <Perdida/>
    <Borrado/>
    <Secundario/>
    <RedSeguridad/>
    <PlanLampa/>
  </div>
</template>

<script>
  import PlanLampa from './PartesLampaPage/PlanLampa'
  import Secundario from './PartesLampaPage/Secundario'
  import Borrado from './PartesLampaPage/Borrado'
  import Perdida from './PartesLampaPage/Perdida'
  import Bloqueo from './PartesLampaPage/Bloqueo'
  import RedSeguridad from './PartesLampaPage/RedSeguridad'
  import Home from './PartesLampaPage/Home'
  import Bajada from '../Home/Bajada'
  import Asistencia from '../Home/Asistencia'
  import SocialNetworks from '../Home/SocialNetworks.vue'

  import {EventBus} from '../../Helpers/events.js'

  export default {
    name: 'HomeIndex',
    // 
    components: {Home, Bajada, Asistencia,SocialNetworks,Bloqueo,Perdida,Borrado,Secundario,RedSeguridad,PlanLampa},
    mounted() {
      EventBus.$on('scrollToElement', refElement => {
        const element = this.$refs[refElement]
        this.$vuetify.goTo(element, {
          duration: 1500,
          offset: 80
        })
      });
    }
  }
</script>