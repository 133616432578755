<template>
	<v-container fill-height fluid style="height: 100vh;background-color: grey;">
		<v-container fill-height>
			<v-row class="text-center" align-center justify-center>
				<v-col cols="12" class="align-center justify-center">
					<v-container fill-height class="ma-auto pb-0 rounded-lg back-badge-login" style="position: relative;">
					<v-alert
						v-show="alert.status"
						dense
						:type="alert.type"
						style="position: absolute;max-width: 375px;top: 5px;"
					>
						<p class="mb-0 caption">{{alert.message}}</p>
					</v-alert>
						<v-row class="text-center px-8 rounded-t-lg" align="center" justify="center">
							<v-col cols="12" class="align-center justify-center">
								<v-img
									:src="require('../../assets/blanco.png')"
									max-height="150"
									contain
								/>
								<h2 class="mt-5 white--text headline font-weight-black" style="line-height: 1.2;">Ingreso Supervisor</h2>
								<p class="white--text mt-3 caption mb-0">Por favor ingresa tu Rut para validarte como Supervisor SOSclick</p>

								<v-text-field
									v-model="rut"
									:rules="required"
									label="Rut"
									required
									dark
								></v-text-field>
								<v-btn
									:loading="sending"
									:disabled="!valid"
									color="#D0282E"
									dark
									block
									class="custom-button-disabled-sos"
									@click="loginSupervisor"
								>
									<p class="mb-0 white--text">Ingresar</p>
								</v-btn>
							</v-col>
						</v-row>
					</v-container>
				</v-col>
			</v-row>
		</v-container>
	</v-container>
</template>

<script>
	import {getSupervisorByID} from '../../Helpers/api/general'
	import { format } from 'rut.js'
	export default {
		name: 'BadgeLogin',
		props: ['loginSupervisorCallback'],
		data: () => ({
			rut: null,
			valid: true,
			alert: {
				status: false,
				type: 'success',
				message: ''
			},
		}),
		mounted(){
		},
		methods: {
			async loginSupervisor(){
				this.rut = format(this.rut)
				const data = {
					rut: this.rut
				}
				const supervisor = await getSupervisorByID(data)
				if(supervisor.supervisor){
					this.loginSupervisorCallback(supervisor.supervisor)
				}
				else{
					this.alert = {
						status: true,
						type: 'error',
						message: 'Lo sentimos no pudimos validar la información o no estás registrado como Supervisor SOSclick.'
					}
					setTimeout(() => {
						this.alert = {
							status: false,
							type: 'success',
							message: ''
						}
					}, 4000);
				}
			}
		}
	}
</script>
