<template>
  <v-container style="background:white" id="servicios" fluid>
    <v-container >
      <v-row>
        <v-col>
          <div class="text-center">
            <p style="color: #d0282e" class="display-2">SOSCLICK</p>
            <p style="color: #d0282e" class="headline">
              se alza como un Servicio de Seguridad
            </p>
            <p class="mx-auto subtitle-1" style="color:#939393; width:60%">
              Ofrece un abanico de características que permiten tanto a
              Usuarios, Comerciantes, Municipios y/o Empresas, el Control,
              Monitoreo y Comunicación, todo en tiempo real, asegurando por ende
              una mejor gestión tanto en el envío de auxilio como en las medidas
              posteriores al Acto Delictual.
            </p>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
export default {
  name: "PostHomeVideo",
  data: () => ({}),
  methods: {},
};
</script>