<template>
  <v-container
    fluid
    style="background-color: #D0282E; min-height: calc(100vh - 600px);"
    fill-height
    class="d-flex flex-column"
  >

  <div class="DeleteContainer">
    <p>
      Esto eliminará tu cuenta de nuestro sistema, se revocarán los permisos
      actuales y no podrás acceder a la aplicación SOSClick. Por favor
      confirma que deseas eliminar tu cuenta escribiendo "eliminar mi cuenta" en
      el siguiente cuadro de texto.
    </p>
            <v-text-field
            v-model="DeleteValid"
            solo
            placeholder="eliminar mi cuenta"
          ></v-text-field>
    <v-btn style="z-index: 1;" :disabled="disable" color="error" @click="deleteAcc">eliminar cuenta</v-btn>
  </div>

  <!-- Modal Cuenta eliminada -->
    <div class="text-center">
    <v-dialog
      v-model="dialog"
      width="500"
    >
    <v-card style="height: 150px; display:flex; justify-content: center; text-align:center;align-items:center;">
        <v-card-text>
            Tu cuenta ha sido eliminada
        </v-card-text>
    </v-card>
    </v-dialog>
  </div>
  </v-container>
</template>

<script>
import { deleteUserAccount } from '../../Helpers/api/general'

export default {
  data() {
    return {
        DeleteValid: '',
        dialog: false
    };
  },
  computed: {
    disable() {
        return this.DeleteValid !== 'eliminar mi cuenta' ? true : false;
    }
  },
  methods: {
    deleteAcc() {
        deleteUserAccount().then(()=> {
            this.dialog = true;
            setTimeout(() => {
                this.dialog = false;
                this.$router.push({path: "/login"});
            }, 2000);
        })
    },
  },
  mounted() {
    const token = localStorage.getItem("userToken");
    !token &&
      this.$router.push({
        path: "/login",
        query: { redirect_to: this.$route.path },
      });
  },
};
</script>

<style lang="scss" scoped>
.DeleteContainer {
    max-width: 500px;
    text-align: justify;
    background-color: #e0e0e0;
    border-radius: 15px;
    padding: 16px;
    margin-top: 100px;
}
</style>
