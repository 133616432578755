<template>
  <div id="BloqueoLampa">
      <v-container fluid style="background:#F7F7F7">
        <v-row class="align-end py-10"> 
          <v-col sm="3" cols="12">
            <v-container :class="!$vuetify.breakpoint.mobile ? 'ml-16':''">
                <div class="text-center">
                  <v-img eager
                  class="mx-auto"
                  width="160"
                  height="auto"
                  :src="require('../../../assets/imgs/icon-bloqueo.svg')"
                  />
                </div>
                <div class="mt-5 mx-auto" style="max-width: 250px;">
                  <p class="headline text-center font-weight-bold mb-0" style="color:#707070">BLOQUEO DE PANTALLA</p>
                  <p class="text-center font-weight-bold mt-0" style="color:#707070">(Sólo Android)</p>
                  <p :class="!$vuetify.breakpoint.mobile ? '':'ml-5'" style="color:#838383">Podrás bloquear el dispositivo remotamente, si no le habías asignado clave a tu dispositivo podrás generar una para que sea solicitada antes de permitir el desbloqueo.</p>
                </div>
            </v-container>
          </v-col>
          <v-col sm="9" cols="12" :style="!$vuetify.breakpoint.mobile ? 'position:relative;':''">
              <v-img eager
                :style="!$vuetify.breakpoint.mobile ? 'max-width: 800px; position: absolute;margin: auto; bottom: 0; left: 0; right: 0;':''"
                height="auto"
                :src="require('../../../assets/imgs/Msnbloqueo.svg')"
                />
          </v-col>
        </v-row>  
      </v-container>

  </div>
</template>

<script>
export default {
  name: "BloqueoLampa",
  data() {
    return {};
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
</style>