import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/components/Home/Index'
import Construction from '@/components/StaticPages/Construction'
import Politicas from '@/components/StaticPages/Politicas'
import SOSCam from '@/components/StaticPages/SOSCam'
import SOSCamExterior from '@/components/StaticPages/SOSCamExterior'
import SOSCamSingle from '@/components/StaticPages/SOSCamSingle'
import Catalog from '@/components/StaticPages/Catalog'
import EstadoOrden from '@/components/StaticPages/EstadoOrden'
import Badge from '@/components/Identifiers/Index'
import Lampa from '@/components/LampaSos/Lampa'
import Login from '@/components/Login/Login'
import Registro from '@/components/Registro/Registro'
import deleteAccount from '@/components/StaticPages/deleteAccount'
import StoreRedirect from '@/components/StoreRedirect'


// import store from '../Stores/stores.js'

Vue.use(Router)

const router = new Router({
	mode: 'history',
	routes: [
		{
			path: '/',
			name: 'Home',
			component: Home
		},
		{
			path: '/SOSCam',
			name: 'SOSCam',
			component: SOSCam
		},
		{
			path: '/SOSCam-EXT-A',
			name: 'SOSCamExterior',
			component: SOSCamExterior
		},
		{
			path: '/catalogo',
			name: 'Catalog',
			component: Catalog
		},
		{
			path: '/finalizar-compra',
			name: 'SOSCamSingle',
			component: SOSCamSingle
		},
		{
			path: '/estado-orden',
			name: 'EstadoOrden',
			component: EstadoOrden
		},
		{
			path: '/soon',
			name: 'Construction',
			component: Construction
		},
		{
			path: '/politicas',
			name: 'Politicas',
			component: Politicas
		},
		{
			path: '/identificador',
			name: 'Badge',
			component: Badge
		},
		{
			path: '/lampa',
			name: 'Lampa',
			component: Lampa
		},
		{
			path: '/login',
			name: 'Login',
			component: Login
		},
		{
			path: '/register',
			name: 'Registro',
			component: Registro
		},
		{
			path: '/delete-account',
			name: 'deleteAccount',
			component: deleteAccount
		},
		{
			path: '/store-redirect',
			name: 'StoreRedirect',
			component: StoreRedirect
		},
	]
})

router.beforeEach((to, from, next) => {
	if (to.name !== 'Home' && to.name !== 'Politicas' && to.name !== 'StoreRedirect' && to.name !== 'Badge' && to.name !== 'Lampa' && to.name !== 'SOSCam' && to.name !== 'SOSCamExterior' && to.name !== 'Catalog' && to.name !== 'SOSCamSingle' && to.name !== 'EstadoOrden'&& to.name !== 'Login'&& to.name !== 'Registro' !== 'Login'&& to.name !== 'deleteAccount') next({ name: 'Home' })
	else next()
})

export default router
