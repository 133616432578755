<template>
  <div>
    <Drawer/>
    <SocialNetworks />
    <Home ref="home"/>
    <PostHomeVideo />
    <RedDeSeguridad/>
    <Asistencia />
    <Bajada ref="servicios" />
    <SosCam ref="soscam"/>
    <SosCamExta ref="soscam-exta"/>
<!--     <Boton /> -->
    <Tracking />
    <PanelDelUsuario/>
    <!-- <Funciones /> -->
    <dashcam />
<!--     <Planes ref="planes" /> -->
  </div>
</template>

<script>
/* eslint-disable */

  import RedDeSeguridad from './RedDeSeguridad'
  import PanelDelUsuario from './PanelDelUsuario'
  import Home from './Home'
  import SosCam from './SosCam'
  import SosCamExta from './SosCamExta'
  import Bajada from './Bajada'
  import Asistencia from './Asistencia'
  import Boton from './Boton'
  // import Funciones from './Funciones'
  import Tracking from './Tracking'
  import Dashcam from './dashcam'
  import Planes from './Planes.vue'
  import SocialNetworks from './SocialNetworks.vue'
  import PostHomeVideo from './PostHomeVideo.vue'

  import {EventBus} from '../../Helpers/events.js'
import Drawer from '../../drawer/Drawer.vue'

  export default {
    name: 'HomeIndex',
    // 
    components: { Home, Bajada, Asistencia, Boton, Tracking, /*Funciones*/ Planes, PanelDelUsuario, Dashcam, RedDeSeguridad, SocialNetworks, SosCam, SosCamExta, PostHomeVideo, Drawer },
    mounted() {
      EventBus.$on('scrollToElement', refElement => {
        const element = this.$refs[refElement]
        this.$vuetify.goTo(element, {
          duration: 1500,
          offset: 80
        })
      });
    }
  }
</script>