<template>
  <v-dialog
    v-model="$store.state.modalVideoState.open"
    :max-width="dialogWidth"
    :scrollable="false"
    persistent
  >
    <v-card class="pa-0" :height="dialogHeight + 'px'" style="overflow-y: hidden;">
      <v-card-text class="pa-0">
        <youtube
          :player-width="dialogWidth"
          :player-height="dialogHeight"
          :video-id="$store.state.modalVideoState.videoID"
          :player-vars="{start: 0, autoplay: 1, modestbranding: 1, rel: 0}"
          @ready="playerReady"></youtube>
      </v-card-text>
      <v-btn
        icon
        color="#D0282E"
        @click="closeModal()"
        style="position: absolute; top: 20px; right: 20px;"
      >
        <v-icon x-large>mdi-close-circle</v-icon>
      </v-btn>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: 'VideoModal',
    data: () => ({
        vid: false,
        dialogWidth: 200,
        dialogHeight: 200
    }),
    mounted() {
      this.setSizes()
    },
    methods: {
      setSizes(){
        this.dialogHeight = window.innerHeight * 0.85
        this.dialogWidth = window.innerWidth * 0.7 
        window.onresize = this.setSizes 
      },
      playerReady(event){
        this.player = event.target
      },
      closeModal(){
        this.$store.commit('setModalVideoState', {videoID: '',open: false})
        this.player.stopVideo()
      }
    },
  }
</script>
