<template>
    <div>
        <v-form ref="form" v-model="valid" lazy-validation style="background-color: #D0282E; z-index: 1;">
                <v-row  class="container" style="max-width: 600px; margin: 0 auto;">
                    <v-img
						max-height="200"
						max-width="200"
						:src="require('../../assets/logo-top.svg')"
                        style="margin: 0 auto;"
						></v-img>
                    <v-col cols="12">
                      <v-text-field dark required :rules="required" v-model="name" label="Nombre"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field dark v-model="email" :rules="emailRules" label="Correo Electrónico" required></v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field dark v-model="rut" :rules="rutRules" @change="(rut) => {formatRut(rut)}" required label="Rut"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field dark maxlength="11" :counter="11" :rules="requiredPhone" required v-model="phone" label="Teléfono"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-select required label="Género" :items="genderOptions" item-text="text" item-value="value"  v-model="sexo"></v-select>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field dark required :rules="required" v-model="address" label="Dirección"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-select :rules="required" label="País" :items="paises" item-text="name" item-value="id" v-model="pais"></v-select>
                    </v-col>
                    <v-col cols="12">
                      <v-select :rules="required" label="Región" :disabled="!pais" :items="regiones" item-text="region" item-value="id" v-model="region"></v-select>
                    </v-col>
                    <v-col cols="12">
                      <v-select :rules="required" label="Comuna" :disabled="!region" :items="comunas" item-text="comuna" item-value="id" v-model="comuna_id"></v-select>
                    </v-col>
                    <v-col cols="12" style="z-index:1;">
                      <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field dark
                    style="z-index: 1 !important;"
                    v-model="birthday"
                    :rules="required"
                    label="Fecha Nacimiento"
                    readonly
                    v-bind="attrs"
                    prepend-inner-icon="mdi-calendar"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  ref="picker"
                  locale="es-ES"
                  v-model="birthday"
                  :max="new Date().toISOString().substr(0, 10)"
                  min="1950-01-01"
                  @change="(date) => $refs.menu.save(date)"
                ></v-date-picker>
              </v-menu>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field dark :rules="required" required type="password" label="Contraseña" v-model="password"></v-text-field>
                    </v-col>
                <span v-if="message" style="display: block; margin-bottom: 8px; color: #FFF;">* {{message}}</span>
                <v-btn :loading="loadingbtn" depressed dark block style="z-index: 1; margin-right:8px;"  color="" @click="register">Registrar</v-btn>
                </v-row>
                

<!--                 <v-alert
                    v-if="alert"
                    :color="success = true ? 'green' : 'red'"
                    :type="success = true ? 'success' : 'error'"
                    style="display:flex; position: absolute;top: 20px;right: 5%;"
                    transition="slide-y-transition"
                    >{{message}}</v-alert> -->
        </v-form>
    </div>
</template>

<script>
import { signUpByCode, login, getPaisesActivos, getRegionesActivas, getComunasActivas } from '../../Helpers/api/general'
import { validate, format } from 'rut.js'


export default {
    data () {
        return {
            valid: true,
            genderOptions: [{text: 'Masculino', value:1}, {text: 'Femenino', value:2}],
            requiredPhone: [
                v => !!v || 'Este campo es obligatorio',
                    v => ( !!v && /^\d+$/.test(v) ) || 'Ingrese sólo números'
                ],
            rutRules: [
                    v => !!v || 'Este campo es obligatorio',
                    v => (!!v || 'Este campo es obligatorio') && (validate(v) || 'Rut no válido')
                ],
            emailRules: [
                    v => !!v || 'Email es obligatorio',
                    v => /.+@.+\..+/.test(v) || 'Email debe ser válido',
                ],
            required: [v => !!v || 'Este campo es obligatorio',],
            loadingbtn: false,
            menu: false,
            paises: [],
            pais: 81,
            regiones: [],
            region: null,
            comunas: [],
            name: '',
            email: '',
            address: '',
            phone: '',
            comuna_id: '',
            birthday: '',
            sexo: '',
            password: '',
            rut: '',
            message: '',
        }
    },
    methods: {
        formatRut(rut) {
            this.rut = format(rut)
        },
        register() {
            const data = {
                name: this.name,
                email: this.email,
                direccion: this.address,
                telefono: this.phone,
                rut: this.rut,
                sexo: this.sexo,
                birthday: this.birthday,
                password: this.password,
                pais: this.pais,
                region: this.region,
                comuna: this.comuna_id
            }
      if(this.$refs.form.validate()){
        this.loadingbtn = true
        signUpByCode(data).then(() => {
          this.loadingbtn = false
            this.message = '';
            this.logIn()
        }).catch(er => {
          this.loadingbtn = false
          this.message = er[0].includes('Ese email ya está en uso, por favor verifica.') ? er[0] : null
        })
      }
    },
    logIn() {
      this.loadingbtn = true
      login({email: this.email, password: this.password}).then(() => {

        this.loadingbtn = false
        this.$router.push('/');
        this.messageLogin = ''
      }).catch(err => {
        if (err.response.status == 401) {
          this.messageLogin = 'Contraseña Incorrecta'
        }
        this.loadingbtn = false
      })
    },
    },
        mounted () {
        getPaisesActivos().then(res => {

            this.paises = res.paises
                getRegionesActivas({pais_id: this.pais}).then(res => {
                this.regiones = res.regiones
        })
    })
    },
      watch: {
    pais: function () {
        getRegionesActivas({pais_id: this.pais}).then(res => {
            this.regiones = res.regiones
        })
    },
    region: function () {
        getComunasActivas({region_id: this.region}).then(res => {
            this.comunas = res.comunas
        })
    },
    menu (val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
    }
  },
}
</script>

<style lang="scss" scoped>

</style>