<template>
  <v-container fill-height fluid style="height: 100vh;background-color: grey;">
    <v-container fill-height>
      <v-btn
        icon
        color="#D0282E"
        @click="logoutSupervisor"
        style="position: absolute; top: 20px; right: 20px;z-index: 99;"
      >
        <v-icon x-large>mdi-close-circle</v-icon>
      </v-btn>
      <v-row class="text-center" align-center justify-center>
        <v-col cols="12" class="align-center justify-center">
          <v-carousel hide-delimiters>
            <v-carousel-item>
              <v-container class="ma-auto py-0 rounded-lg" style="max-width: 400px;border: 1px solid white;">
                <v-row class="text-center px-8 rounded-t-lg back-top-front" align="center" justify="center" style="background-color: #D0282E;">
                  <v-col cols="4" class="align-center justify-center">
                    <v-img
                      :src="require('../../assets/blanco.png')"
                      class="my-3 mx-auto"
                    />
                  </v-col>
                  <v-col cols="8" class="align-center justify-center">
                    <h3 class="white--text text-left font-weight-black" style="line-height: 1.2;">PERSONAL<br/>AUTORIZADO</h3>
                    <h4 class="white--text text-left font-weight-light">WWW.SOSCLICK.CL</h4>
                  </v-col>
                  <v-col cols="4" class="align-center justify-center">
                    <div style="height: 40px;"></div>
                  </v-col>
                </v-row>
                <v-row class="text-center px-8" align="center" justify="center" style="background-color: #e0e0e0;">
                  <v-col cols="12" class="align-center justify-center pb-6" style="position: relative;">
                    <v-avatar size="150" class="ma-auto" style="position: absolute;top:-75px;right:0;left:0;border: 5px solid white;">
                      <v-img
                        :src="user.avatar"
                        class="my-3 mx-auto"
                        height="150"
                      />
                    </v-avatar>
                    <div style="height: 75px;"></div>
                    <h4 class="font-weight-regular text-center ma-auto font-weight-bold" style="color:#5a5a5a;font-size: 1.1em;line-height: 1.2;">{{user.name}}</h4>
                    <h4 class="font-weight-regular subtitle-1 text-center ma-auto" style="color:#5a5a5a;font-size: 1.1em;line-height: 1.2;">{{user.cid | formatRut}}</h4>
                    <h3 class="my-2 font-weight-black text-center headline text-primary">Supervisor</h3>
                  </v-col>
                </v-row>
                <v-row class="text-center py-2 rounded-b-lg" align="center" justify="center" style="background-color: #f2f2f2;">
                  <v-col cols="6" class="align-center justify-center" style="border-right: 1px solid #bdbdbd;">
                    <v-img
                      :src="require('../../assets/imgs/nph-logo.png')"
                      max-height="50"
                      contain
                    />
                  </v-col>
                  <v-col cols="6" class="align-center justify-center">
                    <v-img
                      :src="require('../../assets/imgs/logolampa.png')"
                      max-height="50"
                      contain
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-carousel-item>
            <v-carousel-item>
              <v-container fill-height class="ma-auto pb-0 rounded-lg back-badge">
                <v-row class="text-center px-8 rounded-t-lg" align="center" justify="center">
                  <v-col cols="12" class="align-center justify-center">
                    <v-img
                      :src="require('../../assets/blanco.png')"
                      max-height="150"
                      contain
                    />
                    <h2 class="mt-5 white--text headline font-weight-black" style="line-height: 1.2;">TU RED DE SEGURIDAD</h2>
                    <h4 class="my-2 py-2 white--text font-weight-light" style="border-top: 1px solid white;border-bottom: 1px solid white;">AL ALCANCE DE TUS MANOS</h4>
                    <h4 class="my-2 white--text">WWW.SOSCLICK.CL</h4>
                  </v-col>
                </v-row>
              </v-container>
            </v-carousel-item>
          </v-carousel>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
  import { format } from 'rut.js'
  export default {
    name: 'Badge',
    props: ['user', 'logoutSupervisor'],
    filters: {
      formatRut: function (rut) {
        return format(rut)
      }
    },
    data: () => ({
    }),
    mounted(){
    },
    methods: {
    }
  }
</script>
