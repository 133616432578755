<template>
  <div style="height: 100vh;" class="d-flex flex-column justify-space-between">
    <v-container
      fill-height
      fluid
      class="home-container"
      ref="containerInfo"
      :class="!$vuetify.breakpoint.mobile ? '':'mobile'">
      <video-player
        v-if="loadPlayers"
        class="home-video-background-top"
        ref="videoPlayer"
        :playsinline="true"
        :options="playerOptions">
      </video-player>
      <v-container :class="!$vuetify.breakpoint.mobile ? '':'mt-4'">
        <v-row align="center" justify="center">
          <v-col cols="12" sm="5" style="z-index: 3;">
            <h2 class="font-weight-bold white--text"
              :class="!$vuetify.breakpoint.mobile ? 'display-3':'display-1 text-center'">TU RED DE SEGURIDAD</h2>
            <p class="font-weight-regular white--text"
               :class="!$vuetify.breakpoint.mobile ? 'title':'body-1 text-center'">AL ALCANCE DE TUS MANOS</p>
            <v-btn
              large
              outlined
              rounded
              :block="$vuetify.breakpoint.mobile"
              color="white"
              class="px-6 py-2"
              @click="$store.commit('setModalVideoState', {videoID: 'd80oRIllaYU',open: true})"
            >
              <p class="mb-0 mr-3">Ver Video</p>
              <v-icon>mdi-play</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12" sm="2" class="align-center justify-center text-center px-0" v-show="!$vuetify.breakpoint.mobile">
          </v-col>
          <v-col cols="12" sm="5" v-show="!$vuetify.breakpoint.mobile">
          </v-col>
        </v-row>
      </v-container>
      <v-img eager
        :src="require('../../../assets/imgs/laptop-lampa.png')"
        :style="!$vuetify.breakpoint.mobile ? 'position: absolute; max-width: 45%; right: 0; bottom: 0;z-index: 4':'z-index: 4'"
      />
    </v-container>
    <v-container class="py-0">
      <v-row align="center" justify="center" class="py-0">
        <v-col cols="12" class="py-0 justify-center align-center text-center" style="z-index: 4;">
          <div style="position: relative;">
            <v-img eager
              v-if="!$vuetify.breakpoint.mobile"
              :src="require('../../../assets/imgs/phone-t-40.png')"
              style="position: absolute; bottom: 0; left: 0; right: 0; margin:auto; max-width: 350px; margin-bottom: 5%;"
            >
              <!-- <video-player
                class="home-video-background-phone"
                ref="videoPlayer"
                :playsinline="true"
                :options="playerOptions">
              </video-player> -->
            </v-img>
            <p class="body-2 mt-2 mb-0 text-primary">Conoce SOSCLICK</p>
            <v-icon color="#D0282E" large>mdi-chevron-down</v-icon>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import 'video.js/dist/video-js.css'
  import { videoPlayer } from 'vue-video-player'

  export default {
    name: 'Home',
    components: {videoPlayer},
    data: () => ({
      playerOptions: null,
      playerOptionsB: null,
      loadPlayers: false
    }),
    mounted(){
      const playerWidth = Math.round( this.$refs.containerInfo.clientWidth * 0.95 )
      const playerHeight = Math.round( this.$refs.containerInfo.clientHeight )
      this.playerOptions = {
        // videojs options
        autoplay: true,
        muted: true,
        loop: true,
        fluid: true,
        aspectRatio: playerWidth + ':' + playerHeight,
        controls: false,
        language: 'es',
        sources: [{
          type: "video/mp4",
          src: "/background.mp4"
        }]
      }
      this.playerOptionsB = {
        // videojs options
        autoplay: false,
        muted: false,
        // preload: 'auto',
        fluid: true,
        language: 'es',
        sources: [{
          type: "video/mp4",
          src: "/sosclick.mp4"
        }]
      }
      this.loadPlayers = true
    },
    methods: {
    },
  }
</script>
