<template>
	<div>
		<Loading v-if="loading"/>
		<Badge v-if="!loading && supervisorLogged && userInfo" :user="userInfo" :logoutSupervisor="logoutSupervisor"/>
		<Login v-if="!loading && !supervisorLogged" :loginSupervisorCallback="loginSupervisor"/>
	</div>
</template>

<script>
	import Badge from './Badge'
	import Login from './Login'
	import Loading from './Loading'
	import {getSupervisorByID} from '../../Helpers/api/general'
	export default {
		name: 'BadgeIndex',
		components: {Badge, Login, Loading},
		data: () => ({
			rut: null,
			valid: true,
			loading: true,
			supervisorLogged: false,
			userInfo: null
		}),
		mounted(){
			const supervisorRut = localStorage.getItem('supervisorRut')
			if(supervisorRut){
				this.getSupervisorData(supervisorRut)
			}
			else{
				this.loading = false
				this.supervisorLogged = false
			}
		},
		methods: {
			logoutSupervisor(){
				localStorage.removeItem('supervisorRut')
				this.loading = false
				this.supervisorLogged = false
			},
			loginSupervisor(data){
				localStorage.setItem('supervisorRut', data.cid)
				this.loading = false
				this.userInfo = data
				this.supervisorLogged = true
			},
			async getSupervisorData(rut){
				const data = {rut}
				const supervisor = await getSupervisorByID(data)
				if(supervisor.supervisor){
					this.loginSupervisor(supervisor.supervisor)
				}
				else{
					this.loading = false
					this.supervisorLogged = false
				}
			}
		}
	}
</script>
