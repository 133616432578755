import { endpoints } from "./endpoints";
import request from "../request";

export function cameraList(data) {
  return new Promise((resolve) => {
    request({
      url: endpoints.cameraList,
      method: "post",
      data,
    })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        resolve(error);
      });
  });
}
export function getTuyaCameraVideoInfo(data) {
  return new Promise((resolve) => {
    request({
      url: endpoints.getTuyaCameraVideoInfo,
      method: "post",
      data,
    })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        resolve(error);
      });
  });
}
export function cameraPtzMove(data) {
  return new Promise((resolve) => {
    request({
      url: endpoints.cameraPtzMove,
      method: "get",
      params: data,
    })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        resolve(error);
      });
  });
}

export function getStatusCamera(data) {
  return new Promise((resolve) => {
    request({
      url: endpoints.getStatusCamera,
      method: "post",
      data,
    })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        resolve(error);
      });
  });
}

export function commandTuyaDevice(data) {
  return new Promise((resolve) => {
    request({
      url: endpoints.commandTuyaDevice,
      method: "post",
      data,
    })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        resolve(error);
      });
  });
}

export function getTuyaCamera(data) {
  return new Promise((resolve) => {
    request({
      url: endpoints.getTuyaCamera,
      method: "get",
      data,
    })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        resolve(error);
      });
  });
}
