<template>
  <v-container
    fluid
    class="header-container"
    :class="setClasses()">    
    <v-row align="start" justify="center" class="px-5" v-if="$vuetify.breakpoint.mobile">
      <v-col cols="5" class="py-0">
        <v-img
          :src="require('../../assets/blanco.png')"
          contain
          height="55"
          width="55"
        />
      </v-col>
      <v-col cols="7" class="text-right">
        <v-app-bar-nav-icon dark v-if="$vuetify.breakpoint.mobile" @click.stop="drawerOpen = !drawerOpen" style="z-index: 321;"></v-app-bar-nav-icon>
      </v-col>
    </v-row>    
    <v-row class="pt-2" align="start" justify="center" v-else>
      <v-col cols="5" class="text-center" :style="scrolled > 0 || notInHome ? '':'border-bottom: 1px solid white'">
        <v-btn  style="z-index:1; padding: 0 10px;" text color="white" @click="scrollToElement('home')">
          Home
        </v-btn>
        <v-btn style="z-index:1; padding: 0 10px;" text color="white" @click="scrollToElement('servicios')">
          Servicios
        </v-btn>
<!--         <v-btn style="z-index:1; padding: 0 10px;" text color="white" @click="scrollToElement('planes')">
          Planes
        </v-btn> -->
        <v-btn style="z-index:1; padding: 0 10px;" text color="white" href="https://tutoriales.sosclick.cl/" target="_blank">
          Manuales
        </v-btn>
        <v-btn style="z-index:1; padding: 0 10px;" text color="white" @click="$store.commit('setModalContact', true)">
          Contacto
        </v-btn>
      </v-col>
      <v-col cols="2" class="text-center d-flex justify-center" :class="scrolled > 0 || notInHome ? 'py-0':''">
        <v-img
          :src="require('../../assets/blanco.png')"
          contain
          :height="scrolled > 0 || notInHome ? 55:80"
          :width="scrolled > 0 || notInHome ? 55:80"
        />
      </v-col>
      <v-col cols="5" class="text-center d-flex justify-end" style="gap: 8px;">
        <v-btn
          text
          color="white"
          class="mx-2"
          @click="$router.push('/catalogo')"

          style="z-index:1;"
        >
        <div class="d-flex align-center" style="gap: 8px;">
          <v-icon>mdi-storefront</v-icon>
          Tienda
        </div>
        </v-btn>
<!--         <v-btn
          outlined
          rounded
          color="white"
          class="mx-2"
          href="https://usuarios.sosclick.cl/register"
          target="_blank"
          style="z-index:1;"
        >
          Registro
        </v-btn>
        <v-btn
          outlined
          rounded
          color="white"
          class="mx-2"
          href="https://usuarios.sosclick.cl/login"
          target="_blank"
          style="z-index:1;"
        >
          Iniciar Sesión
        </v-btn> -->
        

      <div @click="$router.push('/finalizar-compra')" style="cursor: pointer; z-index: 123;">
      <v-badge
        style=" font-weight: 600;"
        bordered
        color="error"
        :content="cart_total"
        overlap
        class="mr-5"
      >
        <v-icon color="white" style="font-size: 34px;">mdi-cart-variant</v-icon>
      </v-badge>
      </div>
 
      </v-col>
    </v-row>
    <Drawer :drawerOpen="drawerOpen" :changeDrawerStatus="changeDrawerStatus"/>
  </v-container>
</template>

<script>
  import Drawer from './Drawer'
  import {EventBus} from '../../Helpers/events.js'

  export default {
    name: 'Header',
    components: { Drawer },
    data: () => ({
      scrolled: 0,
      notInHome: false,
      drawerOpen: false
    }),
    computed: {
      cart_total() {
        return this.$store.state.products.reduce((acumulador, producto) => acumulador + producto.qty, 0) || '0';
      }
    },
    created: function () {
      window.addEventListener('scroll', this.handleScroll);
    },
    destroyed: function () {
      window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
      changeDrawerStatus(status){
        this.drawerOpen = status
      },
      handleScroll: function () {
        this.scrolled = window.scrollY
      },
      scrollToElement(element){
        if(this.$router.currentRoute.name == 'Home'){
          EventBus.$emit('scrollToElement', element);
        }
        else{
          this.$router.push({name: 'Home'})
          setTimeout(function() {
            EventBus.$emit('scrollToElement', element);
          }, 600);
        }
      },
      setClasses(){
        let className = ''
        if(this.$router.currentRoute.name != 'Home' && this.$router.currentRoute.name != 'Lampa'){
          this.notInHome = true
          className += 'no-float'
        }
        if(this.scrolled > 0){ className += ' sticky-header' }
        if(this.$vuetify.breakpoint.mobile){ className += ' mobile' }
        return className
      }
    },
  }
</script>
