<template>
	<div>
    <Videos />
    <Contacto />
	</div>
</template>

<script>
  import Videos from './Videos'
  import Contacto from './Contacto'
  export default {
    name: 'ModalsIndex',
    components: {Videos,Contacto},
    data: () => ({
    }),
    mounted() {
    },
    methods: {
    },
  }
</script>
