<template>
  <v-container
    fluid
    class="py-10"
    style="background-color: #d32527;overflow-y: hidden;">
    <v-row align="center" justify="center" class="mb-10">
      <v-col cols="12" class="text-center">
        <v-img
          eager
          :src="require('../../assets/imgs/logo-nph.png')"
          contain
          max-height="80"
        />
      </v-col>
    </v-row>

<v-row class="mb-5">
  <v-col cols="12" md="6">
        <div :style="!$vuetify.breakpoint.mobile ? 'width:30%':''" class="mx-auto">
          <a style="text-decoration: none;" href="https://play.google.com/store/apps/details?id=com.naturalphone.sosclick">
          <v-img
            eager
            :src="require('../../assets/googleplay.png')"
            contain
            max-height="100"
          />
          </a>
        </div>
  </v-col>
  <v-col cols="12" md="6">
      <div :style="!$vuetify.breakpoint.mobile ? 'width:30%':''" class="mx-auto" >
        <a style="text-decoration: none;" href="https://apps.apple.com/us/app/sosclick/id1517076968">
          <v-img
            class=""
            eager
            :src="require('../../assets/AppStore.svg')"
            contain
            max-height="80"
          />
        </a> 
      </div>
   
  </v-col>
</v-row>

    <v-row align="center" justify="center">
      <v-col cols="12"  sm="5" class="text-center py-0 justify-center align-center" :class="!$vuetify.breakpoint.mobile ? '':'d-flex'">
        <v-btn :small="$vuetify.breakpoint.mobile" text color="white" @click="scrollToElement('home')">
          Home
        </v-btn>
        <v-btn :small="$vuetify.breakpoint.mobile" text color="white" @click="scrollToElement('servicios')">
          Servicios
        </v-btn>
        <v-btn :small="$vuetify.breakpoint.mobile" text color="white" @click="scrollToElement('planes')">
          Planes
        </v-btn>
        <v-btn :small="$vuetify.breakpoint.mobile" text color="white" @click="$store.commit('setModalContact', true)">
          Contacto
        </v-btn>
      </v-col>

      <v-col cols="12" sm="7" class="text-center py-0 justify-center align-center">
<!--         <v-btn icon text color="white" href="https://www.youtube.com/channel/UC4TgM9rLa7--NN76gRAJ30Q" target="_blank">
          <v-icon>mdi-facebook</v-icon>
        </v-btn>
        <v-btn icon text color="white" href="https://www.youtube.com/channel/UC4TgM9rLa7--NN76gRAJ30Q" target="_blank">
          <v-icon>mdi-instagram</v-icon>
        </v-btn> -->
        <v-btn icon text color="white" href="https://www.youtube.com/channel/UC4TgM9rLa7--NN76gRAJ30Q" target="_blank">
          <v-icon>mdi-youtube</v-icon>
        </v-btn>
        
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="7" class="pl-0" 
        :class="!$vuetify.breakpoint.mobile ? '':'pr-0'">
        <v-divider color="white"></v-divider>
      </v-col>
      <v-col cols="12" sm="5" :class="!$vuetify.breakpoint.mobile ? '':'py-0'">
        <p class="white--text mb-0" :class="!$vuetify.breakpoint.mobile ? '':'caption text-center'">2020 © Naturalphone S.A. Todos los derechos reservados.</p>
      </v-col>
    </v-row>
    <v-row align="center" justify="center" class="my-0">
      <v-col cols="12" class="text-center my-0 py-0">
        <v-btn style="z-index: 98;position:relative;" small text color="white" @click="toTerminos()">Términos y Condiciones</v-btn> <span class="white--text">-</span>
        <v-btn style="z-index: 98;position:relative;" small text color="white" @click="toPoliticas('Politicas')">Políticas de Privacidad</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import {EventBus} from '../../Helpers/events.js'
  export default {
    name: 'Footer',
    data: () => ({
    }),
    created: function () {
    },
    methods: {
      toTerminos(){
        window.open('/terminos.pdf')
      },
      toPoliticas(route){
        if(this.$router.currentRoute.name != route){
          this.$router.push({name: route})
        }
        window.scrollTo(0, 0);
      },
      scrollToElement(element){
        if(this.$router.currentRoute.name == 'Home'){
          EventBus.$emit('scrollToElement', element);
        }
        else{
          this.$router.push({name: 'Home'})
          setTimeout(function() {
            EventBus.$emit('scrollToElement', element);
          }, 600);
        }
      }
    },
  }
</script>
