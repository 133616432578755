<template>
	<v-container fill-height fluid style="height: 100vh;background-color: grey;">
		<v-container fill-height>
			<v-row class="text-center" align-center justify-center>
				<v-col cols="12" class="align-center justify-center">
					<v-container fill-height class="ma-auto pb-0 rounded-lg back-badge-loading">
						<v-row class="text-center px-8 rounded-t-lg" align="center" justify="center">
							<v-col cols="12" class="align-center justify-center">
								<v-progress-circular indeterminate size="64" color="white"></v-progress-circular>
								<p class="white--text mt-3">Cargando Información</p>
							</v-col>
						</v-row>
					</v-container>
				</v-col>
			</v-row>
		</v-container>
	</v-container>
</template>

<script>
	export default {
		name: 'BadgeLogin',
		data: () => ({
			rut: null,
			valid: true
		}),
		mounted(){
		},
		methods: {
		}
	}
</script>
