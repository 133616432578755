<template>
  <div id="SocketVideo">
    <v-container fluid class="py-0" v-model="VideoLlamada">
      <v-row>
        <v-col sm="6" cols="10">
          <div class="mt-8 ml-7">
            <p style="color: #feb62d" :class="!$vuetify.breakpoint.mobile ? 'display-1' : 'display-1 '">
              <v-icon :class="!$vuetify.breakpoint.mobile ? 'mt-n2' : 'mt-n3 '" color="#FEB62D" style="font-size: 65px"
                >mdi-video-outline</v-icon
              >
              Videollamada
            </p>
          </div>
        </v-col>
        <v-col sm="6" cols="2">
          <div :class="!$vuetify.breakpoint.mobile ? 'mt-12 text-end' : ' mt-9 ml-n10'">
            <v-btn @click="volver(), leave(), deleteChat()" text
              ><v-icon color="#FEB62D" style="font-size: 50px">mdi-arrow-left</v-icon></v-btn
            >
          </div>
        </v-col>
      </v-row>
      <!-- <div class="mt-10 ml-8">
        <p style="color: #feb62d" class="display-2">
          <v-icon class="mt-n2" color="#FEB62D" style="font-size: 65px"
            >mdi-video-outline</v-icon
          >
          Video Call
        </p>
      </div> -->
      <div>
        <v-row :class="$vuetify.breakpoint.mobile ? 'd-flex flex-wrap-reverse':''">
          <v-col :class="!$vuetify.breakpoint.mobile ? 'px-0' : 'mb-10'" sm="4" cols="12">
            <p class="display-1 text-center font-weight-bold" style="color:white" v-if="this.$vuetify.breakpoint.xsOnly">Chat</p>
            <v-container :style="$vuetify.breakpoint.mobile ? 'height: 40vh;background: #f7f7f7':'height: 79.5%;background: #f7f7f7'">
              <v-row>
                <v-col style="overflow: auto; height: 47vh">
                  <v-container
                    v-for="(messag, index) in listMessage"
                    :key="index"
                    style="color: #022d41"
                    class="rounded-lg"
                  >
                    <div v-if="messag.user == 2" class="d-flex flex-row-reverse">
                      <p style="background: #ddd4d4" class="pl-2 pr-5 ml-2 pt-2 pb-0 subtitle-1 rounded-lg">
                        {{ messag.mensaje }}<br />
                        <span
                          style="font-size: 10px; float: right"
                          class="blue-grey--text text--lighten-1 Datecontent"
                          >{{ messag.createdAt }}</span
                        >
                      </p>
                    </div>
                    <div v-else class="d-flex">
                      <p style="background: white" class="pl-2 pr-5 ml-2 pt-2 pb-0 subtitle-1 rounded-lg">
                        {{ messag.mensaje }}<br />
                        <span
                          style="font-size: 10px; float: right"
                          class="
                            blue-grey--text
                            text--lighten-2
                            Datecontent
                            ml-10
                          "
                          >{{ messag.createdAt }}</span
                        >
                      </p>
                    </div>
                  </v-container>
                </v-col>
              </v-row>
            </v-container>

            <div class="mt-3" style="background: #ffffff">
              <v-row class="pt-4">
                <v-col cols="2">
                  <div class="d-flex flex-nowrap ml-5">
                    <v-file-input class="mt-n2 ml-2" />
                  </div>
                </v-col>

                <v-col class="d-flex flex-row" cols="10">
                  <v-text-field
                    style="max-width: 230px"
                    v-on:keyup.13="insertDb()"
                    filled
                    rounded
                    dense
                    placeholder="Escribe un mensaje aquí"
                    v-model="message"
                  >
                  </v-text-field>
                  <v-btn
                    @click="insertDb()"
                    @click.clear="clearMessage"
                    class="ml-2"
                    rounded
                    height="40"
                    width="40px"
                    style="background: #feb62d"
                    icon
                    :disabled="message === ''"
                  >
                    <v-icon class="ml-1" outlined color="white">mdi-send</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </v-col>

          <v-col sm="8" cols="12">
            <v-row>
              <v-col class="px-0">
                <div class="text-center" id="remotes-video" v-show="remoteVideoSet"></div>
                <div id="remote-waiting" class="d-flex flex-column justify-center align-center" v-if="!remoteVideoSet">
                  <v-progress-circular v-if="esperando == 2" indeterminate color="#2250b3"></v-progress-circular>
                  <p v-if="esperando == 2" class="mb-0">
                    Esperando conexión del operador
                  </p>
                  <p v-if="esperando == 3" class="mb-0">
                    Fin de la video llamada
                  </p>
                </div>
              </v-col>
            </v-row>

            <v-row :class="$vuetify.breakpoint.mobile ? 'mt-5':'mt-5 mb-10'">
              <v-col cols="12" sm="6">
                <div :class="$vuetify.breakpoint.mobile ? 'd-flex justify-center align-center':'text-end'">
                  <v-btn
                    @click="disconnect(), deleteChat(), DeleteGeneral()"
                    width="75"
                    height="75"
                    icon
                    :style="
                      !$vuetify.breakpoint.mobile
                        ? 'background-color: #cc2d2d;margin-top: -100px;margin-top: -100px;margin-right: -40px;'
                        : 'background-color: #cc2d2d;margin-top: -20px;'
                    "
                    ><v-icon style="font-size: 40px" color="white">mdi-phone-hangup</v-icon></v-btn
                  >
                </div>
              </v-col>
              <v-col cols="12" sm="6">
                <div
                  :class="!$vuetify.breakpoint.mobile ? 'text-end' : 'text-start'"
                  :style="!$vuetify.breakpoint.mobile ? 'margin-top: -45%' : 'margin-top: -61%'"
                  id="seller_room"
                >
                  <video muted autoplay playsinline :srcObject.prop="localStream"></video>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>

<script>
/* eslint-disable */
import {
  connectToSocket,
  joinRoom,
  start,
  createNewReceivingPeer,
  disconnectFromSocket,
  startRecording,
  stopRecording,
} from "../Helpers/Socket/room";
import moment from "moment";
import { getDatabase, ref, onValue, push, remove } from "firebase/database";
import { videollamada } from "../Helpers/api/calls";
export default {
  props: {
    videomail: {
      type: Function,
    },
    cerrar: {
      type: Function,
    },
    volver: {
      type: Function,
    },
  },
  data() {
    return {
      recordingtype: 1,
      companyId: null,
      VideoLlamada: false,
      esperando: 2,
      remoteVideoSet: false,
      localVideoSet: false,
      idOperator: null,
      salachat: null,
      connected_at: true,
      ended_at: null,
      listMessage: [],
      videoCallID: null,
      roomPrivate: this.$store.state.roomId,
      message: "",
      formSpeciality: {
        speciality: this.$store.state.specialityID,
      },

      localStream: null,
      media: {
        audio: true,
        video: {
          width: { min: 640, ideal: 1280, max: 1920 },
          height: { min: 480, ideal: 720, max: 1080 },
        },
      },
    };
  },
  watch: {
    "$store.state.roomVideoCall"(newValue) {
      console.log("Valor Detectado: ", newValue);
    },
  },

  mounted() {
    // this.startStreaming();
    this.videollamada();
    this.volver;
  },
  methods: {
    DeleteGeneral() {
      // this.writeNewPostSpecialyty();
      this.writeNewPost(this.videoCallID);
    },

    writeNewPost(sala) {
      const db = getDatabase();
      remove(ref(db, "notifications/" + this.companyId + "/" + "common/" + sala));
    },
    // writeNewPostSpecialyty() {
    //   const db = getDatabase();
    //   remove(ref(db, "notifications/" + this.info.company_id + "/" + this.info.id));
    //   //  console.log("funcionaEspecialidad?");
    // },

    clearMessage() {
      this.message = "";
    },
    deleteChat() {
      const db = getDatabase();
      remove(ref(db, "chat/" + this.salachat));
      // console.log("funciona?");
    },
    insertDb() {
      const db = getDatabase();
      push(ref(db, "chat/" + this.salachat), {
        mensaje: this.message,
        createdAt: moment(new Date()).format("HH:mm"),
        user: 2,
      });
      this.clearMessage();
    },
    ListenAlert() {
      const db = getDatabase();
      const starCountRef = ref(db, "chat/" + this.salachat);
      onValue(starCountRef, (snapshot) => {
        const data = snapshot.val();
        this.listMessage = data;
      });
      console.log(this.salachat);
    },

    derivedTest() {
      this.$store.commit("setDerived", 1);
    },

    async videollamada() {
      const self = this;
      const data = this.formSpeciality;
      if (this.roomPrivate == null) {
        videollamada(data)
          .then(function(response) {
            console.log("Clg", response);
            self.companyId = response.data.company_id;
            if (response.success == true) {
              // self.message = "Autorizado para entrar a la sala";
              self.videoCallID = response.data.id;
              self.startStreaming(response.data.id);
              self.salachat = response.data.id;
              self.ListenAlert();
              // console.log(self.salachat)
            } else {
              // Se maneja error con un aviso al cliente
              console.log("Error socket");
            }
          })
          .catch((error) => {
            self.videomail();
            if (!error.success) {
              console.log("No hay operador", error);
              self.derivedTest();
              this.$store.commit("setStep", 1);
            }
            // console.log(error);
          });
      } else {
        console.log("SalaPrivada");
        self.startStreaming(this.roomPrivate);
        self.salachat = 99;
        self.ListenAlert();
      }
    },

    startStreaming(id) {
      connectToSocket(
        "https://vcsocket.click2call.cl:8443/",
        () => {
          joinRoom(id, "Cliente", id, null, () => this.onJoin());
        },
        (message) => this.handleSocketMessage(message)
      );
      this.localVideoSet = true;
      console.log("idJoin", id);
    },

    onJoin() {
      start((localStream) => {
        this.localStream = localStream;
      }, this.media);
    },
    createVideoContainer() {
      const parentVideoElement = document.getElementById("remotes-video");
      const videoElement = document.createElement("video");
      videoElement.id = "remote-video";
      videoElement.autoplay = "autoplay";
      videoElement.playsInline = "playsinline";
      parentVideoElement.appendChild(videoElement);
      this.remoteVideoSet = true;
      return videoElement;
    },
    disconnect() {
      stopRecording();
      disconnectFromSocket();
      console.log("STOP RECORDING");


      this.dialog = false;
      this.$store.commit("setStep", 1);
      this.$store.commit("setSpeciality", null);

      //Funcion para cerrar Drawer
      this.volver();
      console.log("Funciono");
      this.$store.commit("setRoomId", null);
      this.$router.push({ name: "Home" });
      // }
    },

    onNewUserInRoom(user) {
      console.log("onNewUserInRoom: ", user);
      if (user.mediaFlowingOut) {
        this.onVideoReady(user.socketID);
      }
    },
    onVideoReady(socketID) {
      const videoElement = this.createVideoContainer();
      createNewReceivingPeer(socketID, videoElement);
    },
    onMultipleNewUserInRoom(usersInRoom) {
      usersInRoom.forEach((user) => this.onNewUserInRoom(user));
    },
    handleSocketMessage(message) {
      switch (message.id) {
        case "usersInRoom":
          this.onMultipleNewUserInRoom(message.usersInRoom);
          break;
        case "newUserInRoom":
          this.onNewUserInRoom(message.user);
          console.log("Nuevo Usuario");
          break;
        case "onVideoReady":
          // console.log("onVideoReady");
          this.onVideoReady(message.socketID);
          console.log(this.videoCallID, this.recordingtype)
          break;
        case "onUserVideoReady":
          startRecording(this.videoCallID, this.recordingtype);
          console.log("startRecording", this.videoCallID);
          break;

        case "userLeaveRoom":
          console.log("Borrar elementos de video en DOM");
          stopRecording();
          disconnectFromSocket();
          console.log("STOP RECORDING");
          this.remoteVideoSet = false;
          this.localVideoSet = false;
          (this.esperando = 3),
            setTimeout(() => {
              this.cerrar();
              this.$store.commit("setModalButton", 0);
              this.leave();
              // this.$store.commit("setStep", 1);
            }, 5000);
          break;

        default:
          console.log("Unrecognized message", message);
      }
    },
  },
};
</script>

<style>
/* #localViewContainerr video {
  
  
} */
#seller_room video {
  max-width: 130px;
  border-radius: 10%;
}

#remotes-video video,
#remote-waiting {
  width: 100%;
  height: 370px !important;
  max-height: 400px;
  background-color: #f5f5f5;
}
</style>
