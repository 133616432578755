<template>
  <v-container>
    <v-row align-center justify-center>
      <v-col cols="12" class="align-center justify-center">
        <h2 class="text-center text-primary mb-5">Políticas de Privacidad SOSclick</h2>
        <p>La presente Política de Privacidad tiene como finalidad informar a los usuarios del sistema SOSclick sobre el uso y almacenamiento de los datos personales recopilados a través de los sistemas (web, aplicaciones y paneles de administración).</p>

        <h4 class="text-primary">1.  Aceptación de las Condiciones de Uso</h4>
        <p>Para todos los efectos legales y por el mero hecho de acceder a cualquiera de las plataformas SOSclick, el Usuario acepta y reconoce que ha revisado y que está de acuerdo con la Política de Privacidad, en lo que en Derecho corresponda. Será responsabilidad del Usuario la lectura y acatamiento de la Política de Privacidad, cada vez que los utilice.</p>

        <h4 class="text-primary">2.  Información recopilada</h4>
        <p><b>NATURALPHONE S.A.</b> (empresa propietaria de SOSclick), a través de las diferentes plataformas disponibles en SOSclick puede recopilar información del usuario de las siguientes formas:</p>
        <ul>
          <li>Registro: El usuario ingresa los datos personales en nuestro sistema para acceder al servicio.
        Los datos recopilados en este proceso son los siguientes: Nombre, fecha de nacimiento, dirección, región, comuna, rut, género, correo electrónico, teléfono.</li>
          <li>Funcionamiento del sistema: Mientras el usuario tenga el servicio SOSclick activo podremos acceder a la ubicación, en los casos de alertas SOSclick, monitoreo remoto y/o tracking del teléfono.</li>
        </ul>
        <p></p>

        <h4 class="text-primary">3.  Objetivo y alcance de la recopilación de información</h4>
        <p>Los datos personales del usuario son recopilados con los siguientes objetivos:</p>
        <ul>
          <li>Ofrecer asistencia en caso de una emergencia: Nuestro principal objetivo es ofrecer a nuestros usuarios una ayuda real en caso de que tengan un problema o emergencia. La solicitud de los datos se hace con la finalidad de agotar todas las instancias posibles de comunicación con el usuario en caso de una alerta SOSclick.</li>
          <li>Ofrecer herramientas de recuperación del dispositivo: En caso de robo o perdida del dispositivo del usuario, podemos activar funcionalidades para que el usuario pueda recuperar o conocer la ubicación del dispositivo, además de poder eliminar información delicada que pueda tener almacenada en este.</li>
          <li>Ofrecer un sistema de seguimiento: SOSclick permite al usuario activar el seguimiento del teléfono, esto puede ser aplicado a múltiples situaciones. El dispositivo enviará la ubicación en tiempo real al sistema.</li>
        </ul>
        <p></p>
        <h4 class="text-primary">4.  Privacidad de los datos personales</h4>
        <p><b>NATURALPHONE S.A.</b> garantiza al usuario que los datos entregados mediante cualquiera de las plataformas SOSclick (aplicación, web y/o paneles de administración) no será utilizados para otros efectos más que los del funcionamiento del sistema, bajo esta premisa los datos personales pueden ser visualizados sólo por el usuario y por personal de seguridad con acceso a la plataforma y con la finalidad de prestar asistencia al usuario. <b>NATURALPHONE S.A.</b> no cederá ni transferirá estos datos personales, a menos que la legislación vigente así lo indique.</p>

        <p>La aplicación SOSclick podrá acceder a la ubicación del usuario en los siguientes casos:</p>
        <ul>
          <li>Al mantener la aplicación abierta y haber permitido el monitoreo desde el panel de asistencia SOSclick</li>
          <li>En cualquier momento al generar una alerta de seguridad SOSclick. Podremos acceder a la ubicación con la aplicación abierta, cerrada o en background</li>
          <li>En todo momento al activar la función de tracking SOSclick</li>
          <li>En todo momento al activar la funcionalidad de perdida del dispositivo</li>
        </ul>
        <p>La aplicación SOSclick obtendrá los datos de georreferenciación en los casos mencionados anteriormente, no hay otra instancia en la que se pueda conocer la ubicación del dispositivo mediante el sistema SOSclick.</p>
        <p>La aplicación SOSclick puede obtener la ubicación en background (sin necesariamente el conocimiento del usuario) solamente cuando se activa la funcionalidad de tracking, la funcionalidad de perdida del dispositivo o cuando se genera una alarma SOSclick.</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'Politicas',
    data: () => ({
    }),
    mounted(){
    },
    methods: {
    }
  }
</script>
