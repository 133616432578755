<template>
  <v-container style="background:white" id="servicios" fluid>
    <v-container >
      <v-row class="mb-10 mt-10" align="center" justify="center">
        <v-col cols="12">
          <h2 class="display-2 font-weight-bold text-primary text-center">
            DISPOSITIVOS SOSCLICK
          </h2>
          <p
            class="my-5 text-center headline"
            :class="$vuetify.breakpoint.mobile ? '' : 'px-15'"
            style="color: #939393"
          >
            Los dispositivos SOSclick están pensados para facilitar la tarea de
            cuidado y vigilancia personal. Ademas son:
          </p>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-row class="mb-16">
        <v-col
          cols="12"
          sm="4"
          class="px-10 pb-5"
          :style="
            $vuetify.breakpoint.mobile
              ? 'border-bottom: 1px solid #c1c1c1'
              : 'border-right: 1px solid #c1c1c1'
          "
        >
          <v-img
            eager
            :src="require('../../assets/imgs/icon1.svg')"
            height="40"
            contain
          />
          <h4 class="title text-center text-primary mt-4">Fácil de Usar</h4>
          <p class="text-center" style="color: #939393">
            El dispositivo está especialmente creado para ser sencillo de usar
            por cualquier usuario que no esté familiarizado con las nuevas
            tecnologías.
          </p>
        </v-col>
        <v-col
          cols="12"
          sm="4"
          class="px-10 pb-5"
          :style="
            $vuetify.breakpoint.mobile
              ? 'border-bottom: 1px solid #c1c1c1'
              : 'border-right: 1px solid #c1c1c1'
          "
        >
          <v-img
            eager
            :src="require('../../assets/imgs/icon2.svg')"
            height="40"
            contain
          />
          <h4 class="title text-center text-primary mt-4">
            Comunicación Inmediata
          </h4>
          <p class="text-center" style="color: #939393">
            En cualquier momento podremos comunicarnos mediante una video
            conferencia o una simple llamada telefónica.
          </p>
        </v-col>
        <v-col cols="12" sm="4" class="px-10 pb-5">
          <v-img
            eager
            :src="require('../../assets/imgs/icon3.svg')"
            height="40"
            contain
          />
          <h4 class="title text-center text-primary mt-4">Geolocalización</h4>
          <p class="text-center" style="color: #939393">
            Gracias al sistema de GPS de tu móvil podremos hacer un seguimiento
            en tiempo real de nuestros usuarios.
          </p>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
export default {
  name: "Home",
  data: () => ({}),
  methods: {},
};
</script>