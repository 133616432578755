<template>
  <v-btn-toggle
    dense
    dark
    background-color="white"
    multiple
    tile
    class="flex-column"
    style="position: fixed; bottom: 5px;left: 0;z-index: 99999999;"
  >
    <v-btn href="https://www.youtube.com/channel/UC4TgM9rLa7--NN76gRAJ30Q" target="_blank"
      color="#c4302b" small class="py-1" style="height: auto;">
      <v-icon>mdi-youtube</v-icon>
    </v-btn>
    <v-btn href="https://www.facebook.com/SOS.CLICK.CL/" target="_blank"
      color="#3b5998" small class="py-1" style="height: auto;">
      <v-icon>mdi-facebook</v-icon>
    </v-btn>
    <v-btn color="#00acee" small class="py-1" style="height: auto;display:none;">
      <v-icon>mdi-twitter</v-icon>
    </v-btn>
    <v-btn href="https://www.instagram.com/sosclick/" target="_blank"
      color="#405DE6" small class="py-1" style="height: auto;">
      <v-icon>mdi-instagram</v-icon>
    </v-btn>
  </v-btn-toggle>
</template>

<script>
export default {
  name: 'SocialNetworks'
}
</script>