import { render, staticRenderFns } from "./RedSeguridad.vue?vue&type=template&id=65d4fd22&scoped=true"
import script from "./RedSeguridad.vue?vue&type=script&lang=js"
export * from "./RedSeguridad.vue?vue&type=script&lang=js"
import style0 from "./RedSeguridad.vue?vue&type=style&index=0&id=65d4fd22&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65d4fd22",
  null
  
)

export default component.exports